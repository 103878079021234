import {
  ByID,
  ChartingItem,
  DraftWaypointSupplyStrategy,
  ProcedureStatus,
  ProcessingAction,
  Result,
  ResultStatus,
  SampleProcessingAction,
  WaypointActionStatusName,
  WaypointActionType,
} from '@caresend/types';
import { getStore, reportException, toastErrorAndReport } from '@caresend/ui-components';
import { errorSuffix, getUnknownErrorMessage, hasStatus, nullishFilter } from '@caresend/utils';

import { trackChartingDone, trackStartOrFinishOfProcedure } from '@itineraryFlow/helpers/tracking';
import {
  updateChartingItemsForProcedure,
  updateProcedureStatus,
  updateProcessingActionStatus,
  updateWaypointActionStatus,
} from '@itineraryFlow/helpers/updates';

/**
 * The waypoint action status should be set to completed if all procedures on it
 * are completed or canceled
 *
 * An alternative solution would be to listen for procedure status changes in
 * cloud and update the waypoint action status from there
 *
 * This could be refactored to return an updates object
 */
export const maybeUpdateWaypointActionStatus = async (
  waypointActionID: string,
): Promise<void> => {
  try {
    const store = getStore();
    const waypointAction = store.state.waypoint.waypointActions[waypointActionID];
    if (!waypointAction) throw Error(`Waypoint action ${waypointActionID} not found`);

    const procedureIDs = Object.values(waypointAction?.procedures ?? {}).map((idObj) => idObj.id);
    const allProceduresAreCompletedOrCanceledOrFailed = procedureIDs.every((id) => {
      const procedureToCheck = store.state.procedures.procedures[id];
      return hasStatus(procedureToCheck, [
        ProcedureStatus.COMPLETED,
        ProcedureStatus.CANCELED,
        ProcedureStatus.FAILED,
      ]);
    });

    const someProcedureIsComplete = procedureIDs.some((id) => {
      const procedureToCheck = store.state.procedures.procedures[id];
      return hasStatus(procedureToCheck, ProcedureStatus.COMPLETED);
    });

    if (allProceduresAreCompletedOrCanceledOrFailed && someProcedureIsComplete) {
      await updateWaypointActionStatus(
        waypointActionID,
        WaypointActionStatusName.COMPLETE,
      );
    } else if (allProceduresAreCompletedOrCanceledOrFailed && !someProcedureIsComplete) {
      await updateWaypointActionStatus(
        waypointActionID,
        WaypointActionStatusName.CANCELED,
      );
    } else {
      await updateWaypointActionStatus(
        waypointActionID,
        WaypointActionStatusName.IN_PROGRESS,
      );
    }
  } catch (error) {
    toastErrorAndReport(error);
  }
};

export const maybeUpdatePatientWaypointActionStatus = async (
  procedureID: string,
): Promise<void> => {
  try {
    const store = getStore();
    const procedure = store.state.procedures.procedures[procedureID];
    if (!procedure) throw Error(`Procedure ${procedureID} not found`);

    const waypointActions = Object.keys(procedure?.waypointActions ?? {})
      .map((id) => store.state.waypoint.waypointActions[id])
      .filter(nullishFilter);
    const patientWaypointAction = waypointActions.find((waypointAction) =>
      waypointAction?.type === WaypointActionType.PATIENT_ACTION,
    );
    if (!patientWaypointAction) throw Error('Patient waypoint action not found');

    await maybeUpdateWaypointActionStatus(patientWaypointAction.id);
  } catch (error) {
    const errorMessage = getUnknownErrorMessage(error);
    toastErrorAndReport(`Failed to update Waypoint Action status: ${errorMessage}`);
  }
};

export const maybeUpdateProcessingActionsStatus = async (
  waypointID: string,
): Promise<void> => {
  try {
    const store = getStore();
    const waypoint = store.state.waypoint.waypoints[waypointID];
    if (!waypoint) throw Error(`Waypoint ${waypointID} not found`);

    const processingActionIDs = Object.keys(
      waypoint?.processingActions ?? {},
    );

    if (!processingActionIDs.length) return;

    const processingActions = processingActionIDs.map((id) =>
      store.state.waypoint.sampleProcessingActions[id],
    ).filter(nullishFilter);

    const allProceduresAreCanceled = (
      processingAction: ProcessingAction,
    ): boolean => {
      const procedureIDs = Object.values(processingAction?.procedures ?? {}).map((idObj) => idObj.id);
      const procedures = procedureIDs.map((id) => store.state.procedures.procedures[id]).filter(nullishFilter);
      return procedures.every((procedure) => hasStatus(procedure, ProcedureStatus.CANCELED));
    };

    const processingActionsWithOnlyCanceledProcedures = processingActions.filter(
      (processingAction) => allProceduresAreCanceled(processingAction),
    ).filter(nullishFilter);

    const cancelProcessingActions = async (
      actionsToCancel: SampleProcessingAction[],
    ) => {
      await Promise.all(actionsToCancel.map(
        (processingAction) => updateProcessingActionStatus(
          processingAction.id,
          WaypointActionStatusName.CANCELED,
        )),
      );
    };

    if (processingActionsWithOnlyCanceledProcedures.length) {
      await cancelProcessingActions(processingActionsWithOnlyCanceledProcedures);
    }
  } catch (error) {
    console.error(error);
    reportException(error);
  }
};

export const saveChartingAndCompleteProcedure = async (
  procedureID: string,
  waypointID: string,
) => {
  const store = getStore();
  const procedure = store.state.procedures.procedures[procedureID];
  const chartingItems = store.getters['itineraryFlow/getChartingItems'](procedureID);

  if (!procedure || !chartingItems) {
    toastErrorAndReport(
      `There was a problem saving your charting details. ${errorSuffix}`,
    );
    return;
  }

  updateChartingItemsForProcedure(
    procedure.id,
    chartingItems,
    true,
  );

  const procedureFailed = hasStatus(procedure, ProcedureStatus.FAILED);
  /**
   * If we are using the kit shipment strategy, we still need to go through the
   * sample packing flow. We will complete the procedure at the end of that.
   */
  const usingKitShipmentStrategy = getStore().getters[
    'procedures/getSupplyStrategy'
  ](procedureID) === DraftWaypointSupplyStrategy.KIT_SHIPMENT;
  if (usingKitShipmentStrategy) return;
  /**
   * If the procedure has failed then we don't want to override the status
   * with completed
   */
  if (!procedureFailed) {
    await updateProcedureStatus(procedure.id, ProcedureStatus.COMPLETED);
  }
  trackStartOrFinishOfProcedure(waypointID, procedure.id);
};

export const buildResultsFromChartingItemAndTrackEvents = (
  items: ChartingItem[],
  procedureID: string,
  waypointActionID: string,
): ByID<Result> => {
  const store = getStore();
  let results: ByID<Result> = {};

  items.forEach((item) => {
    if (!item.productID) {
      toastErrorAndReport(
        `There was a problem with one of the results. ${errorSuffix}`,
      );
      return;
    }

    // Check if resultsEnabled === true under the product
    const product = store.getters['variables/getProductByID'](item.productID);

    if (!product?.resultsEnabled) return;
    const result: Result = {
      result: item.selection,
      productID: item.productID,
      status: ResultStatus.COMPLETE,
    };
    results = {
      ...results,
      [item.productID]: result,
    };

    trackChartingDone(
      waypointActionID,
      procedureID,
      item.productID,
      result.result,
    );
  });

  return results;
};
