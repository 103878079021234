
import { DynamicCopy } from '@caresend/types';
import { BasicCard, DynamicCopyRender, getStore } from '@caresend/ui-components';
import { computed, defineComponent, onMounted, ref } from 'vue';

import EmptyList from '@/components/shifts/EmptyList.vue';
import ShiftFilters from '@/components/shifts/ShiftFilters/ShiftFilters.vue';
import ShiftsByDate from '@/components/shifts/ShiftsByDate.vue';
import LazyLoading from '@/views/nurse/shifts/LazyLoading.vue';

export default defineComponent({
  name: 'Shifts',
  components: {
    BasicCard,
    DynamicCopyRender,
    EmptyList,
    LazyLoading,
    ShiftFilters,
    ShiftsByDate,
  },
  setup() {
    const store = getStore();

    const isLoading = ref(true);

    onMounted(async () => {
      // await store.dispatch('shifts/bindUserShifts');
      isLoading.value = false;
    });

    const dayLimitIncrement = 20;
    const dayLimit = ref<number>(dayLimitIncrement);

    const displayPastItems = computed<boolean>(() => store.state.settings.calendar.displayPastItems);
    const shiftsByDate = computed(() =>
      store.getters['shifts/getUserShiftsByDate'](
        dayLimit.value,
        displayPastItems.value,
      ),
    );

    const loadItems = () => {
      isLoading.value = true;
      dayLimit.value += dayLimitIncrement;
      isLoading.value = false;
    };

    const isListEmpty = computed<boolean>(() =>
      !Object.keys(shiftsByDate.value).length,
    );

    const dynamicCopy = computed<DynamicCopy | undefined>(() =>
      store.state.variables.variables?.dynamicCopy?.nurseApp?.shifts?.emptyState,
    );

    return {
      displayPastItems,
      dynamicCopy,
      isListEmpty,
      isLoading,
      loadItems,
      shiftsByDate,
    };
  },
});
