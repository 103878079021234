import { ByID, ProcedureStatus, Shipment, WaypointActionStatusName } from '@caresend/types';
import {
  FlowCompletionAction,
  FlowCompletionActions,
  dbGroupSet,
  getStore,
  toastError,
} from '@caresend/ui-components';
import { getShipmentPath, hasStatus } from '@caresend/utils';
import { Route } from 'vue-router';

import { routeNames } from '@/router/model';
import { maybeUpdateWaypointActionStatus } from '@itineraryFlow/helpers/general';
import { trackCheckDob, trackStartOrFinishOfProcedure } from '@itineraryFlow/helpers/tracking';
import { updateProcedureStatus, updateWaypointActionStatus } from '@itineraryFlow/helpers/updates';

const setWaypointActionToInProgress = async (route: Route) => {
  const { waypointActionID } = route.params;
  if (!waypointActionID) throw new Error('Missing waypointActionID when setting waypointAction status to in progress');
  await updateWaypointActionStatus(
    waypointActionID,
    WaypointActionStatusName.IN_PROGRESS,
  );
};

const trackCheckDOBAndUpdateWaypointActionStatus: FlowCompletionAction = async (route: Route) => {
  const { waypointActionID } = route.params;
  if (!waypointActionID) throw new Error('Missing waypointActionID when completing Patient ID step');
  trackCheckDob(waypointActionID);
  await setWaypointActionToInProgress(route);
};

const saveTrackingNumberToShipment: FlowCompletionAction = async (
  route: Route,
) => {
  const store = getStore();
  const procedure = store.getters['procedures/getProcedureByID'](route.params.procedureID);
  if (!procedure) throw Error('Procedure not found');
  if (!procedure.sampleShipmentID) throw Error('No sample shipment ID on Procedure');
  type UpdatesType = Shipment['scannedShippingLabelBarcode'] | Shipment['shippingLabelBarcode'];
  const barcode = store.state.itineraryFlow.localState.scannedShippingLabelBarcode?.[
    route.params.procedureID ?? ''
  ]?.id;
  if (!barcode) throw Error('Missing barcode when saving tracking number');
  const updates: ByID<UpdatesType> = {
    [getShipmentPath(procedure.sampleShipmentID, 'scannedShippingLabelBarcode')]: barcode,
    [getShipmentPath(procedure.sampleShipmentID, 'shippingLabelBarcode')]: barcode,
  };
  /**
   * If the procedure has failed then we don't want to override the status
   * with completed
   */
  if (!hasStatus(procedure, ProcedureStatus.FAILED)) {
    /**
     * Change this logic to return updates instead of setting them so that
     * we can bundle the updates together
     */
    await updateProcedureStatus(procedure.id, ProcedureStatus.COMPLETED);
    const waypointActionID = Object.keys(procedure.waypointActions ?? {})[0];
    if (!waypointActionID) {
      throw Error(
        `Missing waypoint action ID on procedure ${procedure.id} when saving tracking number to shipment`,
      );
    }
    /**
     * Move to a cloud trigger because it's not practical to keep track of all of the places
     * where this needs to be placed
    */
    await maybeUpdateWaypointActionStatus(waypointActionID);
  }
  try {
    await dbGroupSet<UpdatesType>(updates);
  } catch (error) {
    toastError(error);
  }
  const { waypointID } = route.params;
  if (!waypointID) throw new Error('Missing waypointID when completing the sample packing flow');
  trackStartOrFinishOfProcedure(waypointID, procedure.id);
};

/**
 * If a completion action is defined, the next button will trigger that action
 * rather than being a `<router-link>` to the next step. While the async action
 * is in progress, a loader will show on the next button. If the promise
 * resolves, the user will be navigated to the next step.
 *
 * **To cancel navigation, an error must be thrown, or false must be returned.**
 * Errors thrown within these actions will be shown to the user and prevent
 * navigation to the next step.
 */
export const itineraryFlowPatientVisitCompletionActions: FlowCompletionActions = {
  [routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURE_SCAN_SHIPPING_LABEL]: saveTrackingNumberToShipment,
  [routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_PATIENT_PATIENT_ID]: trackCheckDOBAndUpdateWaypointActionStatus,
  [routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_PATIENT_UNLOCK_SUPPLIES]: setWaypointActionToInProgress,
};
