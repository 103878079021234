/* eslint-disable no-console */
import { SupplyShipmentStatusName } from '@caresend/types';
import { FlowStepValidationFailuresGetter, FlowStepValidators, getStore } from '@caresend/ui-components';
import { hasStatus } from '@caresend/utils';

import { routeNames } from '@/router/model';

export const getMailInScanItemsFailures: FlowStepValidationFailuresGetter = (_route) => {
  const { itineraryFlow } = getStore().state;

  const scannableItems = itineraryFlow.localState.mailIn.scanItems;
  const someItemNotScanned = scannableItems.some((item) => !item.isScanned);

  if (someItemNotScanned) {
    return ['Scan all items before continuing.'];
  }

  return null;
};

export const getMailInShipmentsFailures: FlowStepValidationFailuresGetter = (route) => {
  const { waypointID } = route.params;
  if (!waypointID) throw Error('Missing waypoint ID');

  const shipments = getStore().getters[
    'waypoint/getWaypointMailInShipments'
  ](waypointID);
  const someShipmentNotDropped = shipments.some((shipment) =>
    !hasStatus(shipment, SupplyShipmentStatusName.IN_TRANSIT),
  );
  if (someShipmentNotDropped) {
    return ['Drop off all shipments before finalizing.'];
  }
  return null;
};

export const getMailInScanBoxFailures: FlowStepValidationFailuresGetter = (_route) => {
  const { itineraryFlow } = getStore().state;

  const isScanned = !!itineraryFlow.localState.mailIn.scanBox;
  if (!isScanned) return ['Scan the barcode before continuing.'];
  return null;
};

export const getMailInScanLabelFailures: FlowStepValidationFailuresGetter = (_route) => {
  const { itineraryFlow } = getStore().state;

  const isScanned = !!itineraryFlow.localState.mailIn.scanLabel;
  if (!isScanned) return ['Scan the barcode before continuing.'];
  return null;
};

export const getMailInTakePhotoFailures: FlowStepValidationFailuresGetter = (_route) => {
  const { itineraryFlow } = getStore().state;

  const isScanned = !!itineraryFlow.localState.mailIn.takePhoto;
  if (!isScanned) return ['Take a photo before continuing.'];
  return null;
};

/**
 * Validators to determine when the `Next` button should be enabled on
 * order flow steps.
 */
export const itineraryFlowMailInStepValidators: FlowStepValidators = {
  [routeNames.ITINERARY_FLOW_PACKED_MAIL_IN_WAYPOINT_SCAN_ITEMS]:
    getMailInScanItemsFailures,
  [routeNames.ITINERARY_FLOW_PACKED_MAIL_IN_WAYPOINT_SHIPMENTS]:
    getMailInShipmentsFailures,
  [routeNames.ITINERARY_FLOW_PACKED_MAIL_IN_WAYPOINT_SHIPMENT_SCAN_BOX]:
    getMailInScanBoxFailures,
  [routeNames.ITINERARY_FLOW_PACKED_MAIL_IN_WAYPOINT_SHIPMENT_SCAN_LABEL]:
    getMailInScanLabelFailures,
  [routeNames.ITINERARY_FLOW_PACKED_MAIL_IN_WAYPOINT_SHIPMENT_TAKE_PHOTO]:
    getMailInTakePhotoFailures,
};
