import {
  ByID,
  DbRef,
  EncryptedProcedure,
  Itinerary,
  ItineraryOfferStatus,
  ItineraryStatus,
  Procedure,
  ProcedureStatus,
  SampleStatus,
  SupplyType,
  TrackingEvent,
  Waypoint,
  WaypointAction,
  WaypointActionType,
} from '@caresend/types';
import { dbGroupSet, dbSet, getStore, setItineraryDataInStore } from '@caresend/ui-components';
import { deduplicateArrayOfObjectsByID, hasStatus, isNullish, nullishFilter } from '@caresend/utils';

import { SupplyListItem } from '@/components/itinerary/OfferAlert/model';
import { assignNurseToItineraryRequest, getUserItinerariesRequest } from '@/database/firebase/API';
import { getPrescriberIDByWaypointID } from '@/functions/itinerary/waypoints';
import { trackDebugEvent, trackEvent } from '@/functions/tracking/tracking';

export const getPrescriberIDByItineraryID = (
  itineraryID: string,
): string | undefined => {
  const itinerary = getStore().state.waypoint.itineraries[itineraryID];
  return getPrescriberIDByWaypointID(itinerary?.waypoints[0]?.id ?? '');
};

export const getProceduresByWaypointActionID = (
  waypointActionID: string,
): Procedure[] => {
  const store = getStore();
  const waypointAction = store.state.waypoint.waypointActions[waypointActionID];
  const procedures = Object.keys(waypointAction?.procedures ?? {}).map(
    (id) => store.state.procedures.procedures[id],
  ).filter(nullishFilter);
  return procedures;
};

export const getProceduresByWaypointID = (
  waypointID: string,
): Procedure[] => {
  const waypoint = getStore().state.waypoint.waypoints[waypointID];
  let procedures = waypoint?.waypointActions?.map(
    (idObj) => getProceduresByWaypointActionID(idObj.id),
  ).filter(nullishFilter).flat();
  if (!procedures) return [];
  procedures = deduplicateArrayOfObjectsByID(procedures);
  return procedures ?? [];
};

export const allWaypointProceduresHaveSupplyTransfers = (
  waypointID: string,
): boolean => {
  const procedures = getProceduresByWaypointID(waypointID);
  const supplyTransfersByProcedure: ByID<string> = {};
  const someProcedureMissingSupplyTransfer = procedures.some(
    (procedure) => {
      supplyTransfersByProcedure[procedure.id] = procedure.supplyTransferID ?? 'no supply transfer';
      return !procedure.supplyTransferID;
    },
  );
  trackDebugEvent('supplyTransfersOnProcedures', {
    supplyTransfersByProcedure,
    allWaypointProceduresHaveSupplyTransfers: !someProcedureMissingSupplyTransfer,
  });
  return !someProcedureMissingSupplyTransfer;
};

/**
 * Get procedures for display when dropping off samples. Procedures must not
 * be canceled and must have at least one sample that was collected, or already
 * dropped off (completed).
 */
export const getProceduresWithCollectedSamples = (
  waypointID: string,
): Procedure[] => {
  const procedures = getProceduresByWaypointID(waypointID);
  const withoutTerminatedProcedures = procedures.filter(
    (procedure) => !hasStatus(procedure.status, [
      ProcedureStatus.CANCELED,
      ProcedureStatus.FAILED,
    ]),
  );
  const withCollectedSamples = withoutTerminatedProcedures.filter((procedure) =>
    Object.values(procedure.samples ?? {}).some((sample) =>
      hasStatus(sample, [
        SampleStatus.COLLECTED,
        SampleStatus.COMPLETED,
      ]),
    ),
  );
  return withCollectedSamples;
};

export const acceptItinerary = async (itineraryID: string): Promise<boolean> => {
  const userID = getStore().state.auth.user?.id;
  if (!userID) return false;
  try {
    await assignNurseToItineraryRequest({ itineraryID, userID });
    return true;
  } catch (error: any) {
    return false;
  }
};

export const declineItineraryOffer = async (itineraryID: string, reason: string): Promise<void> => {
  const userID = getStore().state.auth.user?.id;
  if (!userID) return;

  const updates = {
    [`${DbRef.USERS}/${userID}/itineraryOffers/${itineraryID}/declineReason`]: reason,
    [`${DbRef.USERS}/${userID}/itineraryOffers/${itineraryID}/status`]: ItineraryOfferStatus.DECLINED,
    [`${DbRef.ITINERARIES}/${itineraryID}/nursesOffered/${userID}/declineReason`]: reason,
    [`${DbRef.ITINERARIES}/${itineraryID}/nursesOffered/${userID}/status`]: ItineraryOfferStatus.DECLINED,
  };

  await dbGroupSet<ItineraryOfferStatus | string>(updates);

  trackEvent(TrackingEvent.NURSE_DECLINED_ITINERARY_OFFER, {
    itineraryID,
    nurseID: userID,
    declineReason: reason,
  });
};

export const updateItineraryStatus = async (itineraryID: string, newStatus: ItineraryStatus) => {
  await dbSet<ItineraryStatus>(`${DbRef.ITINERARIES}/${itineraryID}/status`, newStatus);
};

export const getWaypointActionsByWaypoint = (waypoint: Waypoint): WaypointAction[] => {
  if (!waypoint.waypointActions) return [];
  const waypointActions = waypoint.waypointActions.map((waypointAction) =>
    getStore().getters['waypoint/getWaypointActionByID'](waypointAction.id)) as WaypointAction[];
  return waypointActions.filter((waypointAction) => waypointAction !== undefined);
};

export const isItineraryCompleted = (itinerary: Itinerary | undefined): boolean => {
  if (itinerary === undefined) return false;
  return hasStatus(itinerary, ItineraryStatus.COMPLETED);
};

export const getItineraryStatus = (itineraryID: string | undefined): ItineraryStatus | undefined => {
  const itinerary = getStore().getters['waypoint/getItineraryByID'](itineraryID);
  if (itinerary) {
    return itinerary.status;
  }
  return undefined;
};

export const isItinerarySingleStop = (itineraryID: string | undefined): boolean => {
  if (!itineraryID) return false;
  const itinerary = getStore().getters['waypoint/getItineraryByID'](itineraryID);
  if (!itinerary) return false;
  return itinerary.waypoints.length === 1;
};

export const loadNurseItineraries = async (): Promise<void> => {
  const store = getStore();
  const nurseID = store.state.auth.user?.id;
  if (!nurseID) return;

  const { nurseItineraries, itineraryDataById } = await getUserItinerariesRequest({
    includeWaypointsDataInItineraryData: true,
  });
  setItineraryDataInStore(store.commit, Object.values(itineraryDataById));
  if (nurseItineraries) {
    store.commit('waypoint/SET_NURSE_ITINERARIES', nurseItineraries);
  }
};

export const getPatientActionsByItineraryID = (
  itineraryID: string,
): WaypointAction[] => {
  const store = getStore();
  const itinerary = store.getters['waypoint/getItineraryByID'](itineraryID);
  if (isNullish(itinerary)) return [];
  return itinerary.waypoints.map(
    (waypointIDObj) => store.getters['waypoint/getWaypointByID'](
      waypointIDObj.id,
    )?.waypointActions)
    .flat(1)
    .map((waypointAction) => store.getters['waypoint/getWaypointActionByID'](
      waypointAction?.id,
    ))
    .filter((
      waypointAction,
    ) => waypointAction?.type === WaypointActionType.PATIENT_ACTION)
    .filter(nullishFilter);
};

export const getSupplyListFromProcedures = (
  procedures: ByID<EncryptedProcedure | undefined>,
): SupplyListItem[] =>
  Object.values(procedures).map(
    (procedure) => Object.values(
      procedure?.supplyInstructionConfig?.supplyInstructions ?? {},
    ),
  )
    .flat(1)
    .map(
      (instruction): SupplyListItem | null => {
        const supply = getStore().getters['variables/getSupplyByID'](
          instruction.supplyID,
        );
        if (!supply) return null;
        return {
          id: instruction.supplyID,
          name: supply.name,
          quantity: instruction.quantity,
        };
      },
    )
    .filter(nullishFilter);

export const getSuppliesByItineraryID = (
  itineraryID: string,
) => {
  const patientActions = getPatientActionsByItineraryID(itineraryID);
  let procedures = {};
  patientActions.forEach((action) => {
    Object.keys(action.procedures ?? {}).forEach((procedureID) => {
      const procedure = getStore().getters['procedures/getProcedureByID'](procedureID);
      procedures = {
        ...procedures,
        [procedureID]: procedure,
      };
    });
  });
  return getSupplyListFromProcedures(procedures);
};

export const procedureContainsTubes = (
  procedureID: string,
): boolean => {
  const samples = getStore().getters['procedures/getSamplesByProcedureID'](
    procedureID,
  );
  return samples.some((sample) => sample.type === SupplyType.TUBE);
};
