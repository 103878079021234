import { BookingActions, BookingInput, BookingState } from '@/store/modules/booking/model';

type S = BookingState;

const bookingState: S = {
  bookingsInput: {},
};

const bookingMutations = {
  'booking/SET_BOOKING_INPUT': (state: S, newBookingInput: Partial<BookingInput>) => {
    if (!newBookingInput.id) return;
    state.bookingsInput = {
      ...state.bookingsInput,
      [newBookingInput.id]: {
        ...state.bookingsInput[newBookingInput.id],
        ...newBookingInput,
      },
    };
  },
};

const bookingActions: BookingActions = {
};

const bookingGetters = {};

export type BookingGetters = typeof bookingGetters;

export const bookingModule = {
  state: bookingState,
  mutations: bookingMutations,
  actions: bookingActions,
  getters: bookingGetters,
};

export type BookingModule = typeof bookingModule;
