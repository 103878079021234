
import { FeatureFlag, ServiceRegion, Shift, ShiftStatus } from '@caresend/types';
import { BasicCard, getStore } from '@caresend/ui-components';
import { formatShiftDateTime, hasStatus } from '@caresend/utils';
import { PropType, computed, defineComponent } from 'vue';
import { Location } from 'vue-router';

import { howLongUntilShiftStarts } from '@/functions/shifts';
import { shiftRoute } from '@/router/locations';

export default defineComponent({
  name: 'ShiftCard',
  components: { BasicCard },
  props: {
    shift: {
      type: Object as PropType<Shift>,
      required: true,
    },
  },
  setup(props) {
    const store = getStore();

    const formattedShift = computed<string | undefined>(() =>
      formatShiftDateTime(props.shift, false),
    );

    const shiftServiceRegionNames = computed<string[]>(() =>
      store.getters[
        'shifts/getShiftServiceRegions'
      ](props.shift)?.map((region: ServiceRegion) => region.name) ?? [],
    );

    const jobCount = computed<number>(
      () => Object.values(props.shift?.itineraries ?? {}).length,
    );

    const jobLabel = computed<string | null>(() =>
      `${jobCount.value} job${jobCount.value === 1 ? '' : 's'}`,
    );

    const shiftRouteWithID = computed<Location>(
      () => shiftRoute({ params: { shiftID: props.shift?.id ?? '' } }),
    );

    const disableShiftSupplyPreparation = computed<boolean | undefined>(
      () => store.state.variables.featureFlags[FeatureFlag.DISABLE_SHIFT_SUPPLY_PREPARATION],
    );

    const displayCheckInAvailable = computed<boolean>(() => {
      const hoursUntilShiftStarts = howLongUntilShiftStarts(props.shift, 'hour');
      const shiftStartsWithin24Hours = (
        hoursUntilShiftStarts < 24
        && hoursUntilShiftStarts > 0
      );
      const shiftEligibleForCheckin = hasStatus(props.shift, ShiftStatus.ACCEPTED);

      return (
        !disableShiftSupplyPreparation.value
        && shiftStartsWithin24Hours
        && shiftEligibleForCheckin
      );
    });

    return {
      displayCheckInAvailable,
      disableShiftSupplyPreparation,
      formattedShift,
      jobCount,
      jobLabel,
      shiftRoute,
      shiftRouteWithID,
      shiftServiceRegionNames,
    };
  },
});
