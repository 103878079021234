import { render, staticRenderFns } from "./LazyLoading.vue?vue&type=template&id=5ac5236c&scoped=true&"
import script from "./LazyLoading.vue?vue&type=script&lang=ts&"
export * from "./LazyLoading.vue?vue&type=script&lang=ts&"
import style0 from "./LazyLoading.vue?vue&type=style&index=0&id=5ac5236c&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ac5236c",
  null
  
)

export default component.exports