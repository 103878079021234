import {
  ExitRouteParams,
  ExtendedCustomModule,
  SchedulingFlowModule,
  draftWaypointDetailsRoute,
  initSchedulingFlowModule,
} from '@caresend/ui-components';

import type { RootState } from '@/store/model';

const extraSchedulingFlowGetters = {
  'schedulingFlow/getExitRoute': () =>
    ({ id }: ExitRouteParams) => id
      ? draftWaypointDetailsRoute({ params: {
        draftWaypointID: id,
      } })
      : undefined,
};

const schedulingFlowModuleExtension = {
  getters: extraSchedulingFlowGetters,
};

export const schedulingFlowModule: ExtendedCustomModule<
  SchedulingFlowModule<RootState>,
  typeof schedulingFlowModuleExtension
> = initSchedulingFlowModule(schedulingFlowModuleExtension);

export type ExtendedSchedulingFlowModule = typeof schedulingFlowModule;

export type ExtendedSchedulingFlowMutations = ExtendedSchedulingFlowModule['mutations'];
export type ExtendedSchedulingFlowActions = ExtendedSchedulingFlowModule['actions'];
export type ExtendedSchedulingFlowGetters = ExtendedSchedulingFlowModule['getters'];
