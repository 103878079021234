import { buildMaybePartnerRoute } from '@caresend/ui-components';

import { LocationData, routeNames } from '@/router/model';

export const acceptedSamplesRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.ACCEPTED_SAMPLES });

export const actionBlockedRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.ACTION_BLOCKED });

export const addCoworkerRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.ADD_COWORKER });

export const addSuppliesRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.ADD_SUPPLIES });

export const addSuppliesToPlaceRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.ADD_SUPPLIES_TO_PLACE });

export const addProcedureRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.ADD_PROCEDURE });

export const assistantSignUpRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.ASSISTANT_SIGN_UP });

export const availabilityRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.AVAILABILITY });

export const bookingConfirmationRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.BOOKING_CONFIRMATION });

export const bookingConfirmedRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.BOOKING_CONFIRMED });

export const centrifugationRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({
    ...locationData,
    name: routeNames.ITINERARY_FLOW_CENTRIFUGATION_SCAN_SPECIMEN_BAGS,
  });

export const chartingRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({
    ...locationData,
    name: routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURE_CHARTING,
  });

export const chartingSamplesRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({
    ...locationData,
    name: routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURE_CHARTING_SAMPLES,
  });

export const checkInRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.CHECK_IN });

export const connectBankAccountRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.CONNECT_BANK_ACCOUNT });

export const currentStepBookingRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.CURRENT_STEP_BOOKING });

export const draftWaypointDetailsRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.DRAFT_WAYPOINT_DETAILS });

export const dropOffDetailsRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.DROP_OFF_DETAILS });

export const doneRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.DONE });

export const dropOffScanSpecimenBagRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.SCAN_SPECIMEN_BAG });

export const editProcedureRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.EDIT_PROCEDURE });

export const finishSignUpRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.FINISH_SIGN_UP });

export const forgotPasswordRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.FORGOT_PASSWORD });

export const goToPatientRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({
    ...locationData,
    name: routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_GO_TO_PATIENT,
  });

export const goToPlaceWaypointRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.GO_TO_PLACE_WAYPOINT });

export const itineraryRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.ITINERARY });

export const joinOfficeRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.JOIN_OFFICE });

export const legacyItineraryRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.ITINERARY_FLOW_ITINERARY });

export const locationSettingsRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.LOCATION_SETTINGS });

export const loginRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.LOGIN });

export const manageTeamRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.MANAGE_TEAM });

export const myProceduresSettingsRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.MY_PROCEDURES_SETTINGS });

export const mySuppliesRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.MY_SUPPLIES });

export const notFoundRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.NOT_FOUND });

export const nurseIDRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({
    ...locationData,
    name: routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_NURSE_IDENTITY,
  });

export const nurseSignUpRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.NURSE_SIGN_UP });

export const nurseVisitsRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.NURSE_VISITS });

export const orderKitRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.ORDER_KIT });

export const offerRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.OFFER });

export const offerConfirmationRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.OFFER_CONFIRMATION });

export const offerConfirmedRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.OFFER_CONFIRMED });

export const officeBookingDetailsRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.OFFICE_BOOKING_DETAILS });

export const officeOrderDetailsRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.OFFICE_ORDER_DETAILS });

export const officeOrdersRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.OFFICE_ORDERS });

export const officeProcedureDetailsRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.OFFICE_PROCEDURE_DETAILS });

export const officeSettingsRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.OFFICE_SETTINGS });

export const packingFlowRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({
    ...locationData,
    name: routeNames.ITINERARY_FLOW_UNPACKED_MAIL_IN_ACTION_SCAN_MAIL_IN_BOX,
  });

export const patientDiagnosisRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({
    ...locationData,
    name: routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURE_PATIENT_DIAGNOSIS,
  });

export const patientIDRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({
    ...locationData,
    name: routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_PATIENT_PATIENT_ID,
  });

export const patientListRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({
    ...locationData,
    name: routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_PATIENT_LIST,
  });

export const patientWaypointDetailsRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({
    ...locationData,
    name: routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_DETAILS,
  });

// TODO: reimplement or delete (was used for pick-up stop)
// export const placeWaypointRoute = (locationData?: LocationData) =>
//   buildMaybePartnerRoute({ ...locationData, name: routeNames.PLACE_WAYPOINT });

export const proceduresSupplyRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({
    ...locationData,
    name: routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURES_SUPPLY,
  });

export const packingRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.PICK_AND_PACK });

export const placeDetailsRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.PLACE_DETAILS });

export const prescriberSignUpRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.PRESCRIBER_SIGN_UP });

export const procedureListRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.PROCEDURE_LIST });

export const procedureRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.PROCEDURE });

export const profileRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.PROFILE });

export const reportsRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.REPORTS });

export const resetPasswordRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.RESET_PASSWORD });

export const requestFilledRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.REQUEST_FILLED });

export const scanProcedureSupplyRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({
    ...locationData,
    name: routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURE_SCAN_PROCEDURE_SUPPLY,
  });

export const scanProcedureSuppliesRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({
    ...locationData,
    name: routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURE_SCAN_PROCEDURE_SUPPLIES,
  });

export const scanRejectedSamplesRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.SCAN_REJECTED_SAMPLES });

export const scanRejectedSamplesStartRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.SCAN_REJECTED_SAMPLES_START });

export const scanShiftBagRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.SCAN_SHIFT_BAG });

export const scanWaypointSupplyRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({
    ...locationData,
    name: routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_SCAN_WAYPOINT_SUPPLY,
  });

export const samplesDropOffRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.SAMPLES_DROP_OFF });

export const serviceRegionGroupSettingsRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.SERVICE_REGION_SETTINGS });

export const shippingRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.SHIPPING });

export const shiftPreferencesRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.SHIFT_PREFERENCES });

export const shiftSuppliesRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.SCAN_SHIFT_SUPPLIES });

export const shiftRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.SHIFT });

export const shiftLeaveFromRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.LEAVE_FROM });

export const signUpRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.SIGN_UP });

export const shiftOfferRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.SHIFT_OFFER });

export const supplyTransferCloseProcedureKitRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.SUPPLY_TRANSFER_CLOSE_PROCEDURE_KIT });

export const supplyTransferDonePackingRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.SUPPLY_TRANSFER_DONE_PACKING });

export const supplyTransferPrintInstructionSheetRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.SUPPLY_TRANSFER_PRINT_INSTRUCTION_SHEET });

export const supplyTransferPrintShippingLabelRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.SUPPLY_TRANSFER_PRINT_SHIPPING_LABEL });

export const supplyTransferProceduresRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.SUPPLY_TRANSFER_PROCEDURES });

export const supplyTransferScanInstructionSheetRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.SUPPLY_TRANSFER_SCAN_INSTRUCTION_SHEET });

export const supplyTransferScanKitsToSupplyBoxRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.SUPPLY_TRANSFER_SCAN_KITS_TO_SUPPLY_BOX });

export const supplyTransferScanProcedureKitRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.SUPPLY_TRANSFER_SCAN_PROCEDURE_KIT });

export const supplyTransferScanProcedureSuppliesRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.SUPPLY_TRANSFER_SCAN_PROCEDURE_SUPPLIES });

export const supplyTransferScanShippingLabelRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.SUPPLY_TRANSFER_SCAN_SHIPPING_LABEL });

export const supplyTransferScanSupplyBoxRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.SUPPLY_TRANSFER_SCAN_SUPPLY_BOX });

export const supplyTransferSupplyBoxPhotoRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.SUPPLY_TRANSFER_SUPPLY_BOX_PHOTO });

export const unlockSuppliesRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({
    ...locationData,
    name: routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_PATIENT_UNLOCK_SUPPLIES,
  });

export const userSettingsRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.USER_SETTINGS });

export const verifyEmailRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.VERIFY_EMAIL });

export const workAreaSettingsRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.WORK_AREA_SETTINGS });

export const workSettingsRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.WORK_SETTINGS });

export const waypointSupplyRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({
    ...locationData,
    name: routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_WAYPOINT_SUPPLY,
  });

export const walletRoute = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.MY_WALLET });

export const withdrawWalletRouter = (locationData?: LocationData) =>
  buildMaybePartnerRoute({ ...locationData, name: routeNames.WITHDRAW_WALLET_BALANCE });
