import {
  ByID,
  LabelSupplyInstructionOption,
  Procedure,
  ProcedureStatus,
  Role,
  SupplyInstruction,
  SupplyInstructionOptions,
  SupplyItemWithSupplyID,
  SupplyType,
} from '@caresend/types';
import { getStore, getUserRole } from '@caresend/ui-components';
import {
  generateID,
  hasStatus,
  hasTypeOfSupplyInstructionSingleCheck,
  nullishFilter,
  objToKeyValueArray,
} from '@caresend/utils';

import { getProceduresByWaypointActionID } from '@/functions/itinerary/itinerary';
import { ScannableItemWithKey, SupplyToCheck } from '@/functions/supplies/scanning';

// WAYPOINT ACTION

export const initWaypointActionSuppliesToScan = (
  waypointActionID: string,
): ScannableItemWithKey[] => {
  const store = getStore();

  const procedures = getProceduresByWaypointActionID(waypointActionID);

  const getScannableItem = (
    supplyItemWithSupplyID: SupplyItemWithSupplyID,
  ): ScannableItemWithKey | undefined => {
    const supply = store.getters['variables/getSupplyByID'](
      supplyItemWithSupplyID.supplyID,
    );
    if (!supply) return;
    const supplyItem = supplyItemWithSupplyID.id
      ? store.state.supplies.supplyItems?.[supplyItemWithSupplyID.id]
      : null;
    return {
      color: supply?.color,
      imageColor: supply.icon,
      isScanned: false,
      key: generateID(),
      name: supply?.name,
      serial: supplyItem?.serial,
      supplyID: supply?.id,
    };
  };

  /**
   * The clinician must unlock both kit supply items and procedure bags
   *
   * At this stage, a procedure will have either a kit supply item or a
   * procedure bag
   */
  const supplies: ScannableItemWithKey[] = procedures.map(
    (procedure) => {
      if (hasStatus(procedure, ProcedureStatus.CANCELED)) return;
      if (procedure.kitSupplyItem) {
        return getScannableItem(procedure.kitSupplyItem);
      }
      return undefined;
    },
  ).filter(nullishFilter);

  return supplies;
};

// PROCEDURE

/**
 * Gets supply instructions as an array of strings, except centrifuge, which is
 * always excluded when displaying samples to clinician.
 */
export const getSupplyInstructionsLabels = (
  supplyInstructions: SupplyInstruction['instructions'] | undefined,
): string[] => {
  const supplyInstructionsLabels: string[] = [];

  supplyInstructions?.forEach((items) => {
    const supplyInstructionsKeyVal = objToKeyValueArray(items);
    supplyInstructionsKeyVal.forEach(({ key: supplyOption, value: hasInstruction }) => {
      if (hasInstruction && supplyOption !== SupplyInstructionOptions.CENTRIFUGE) {
        supplyInstructionsLabels.push(LabelSupplyInstructionOption[supplyOption]);
      }
    },
    );
  });
  return supplyInstructionsLabels;
};

const getSupplyItemIDsBySupplyID = (
  items: ByID<SupplyItemWithSupplyID>,
): ByID<string[]> =>
  Object.values(items).reduce<ByID<string[]>>((quantities, item) => {
    const { supplyID } = item;
    const { id: supplyItemID } = item;
    if (!supplyID) throw Error('Missing supply ID');
    if (!supplyItemID) throw Error('Missing supply item ID');
    return {
      ...quantities,
      [supplyID]: [...(quantities[supplyID] || []), supplyItemID],
    };
  }, {});

export const initProcedureSuppliesScannableItems = ({ procedure, limitTo }: {
  procedure: Procedure | undefined;
  limitTo?: 'sampleBags' | 'nonSampleBags';
}): ScannableItemWithKey[] => {
  const instructions = Object.values(
    procedure?.supplyInstructionConfig?.supplyInstructions ?? {},
  );

  const supplyItemIDsBySupplyID = getSupplyItemIDsBySupplyID(
    procedure?.supplyItems ?? {},
  );

  const scannableItems = instructions.reduce<ScannableItemWithKey[]>(
    (items, instruction) => {
      const supply = getStore().getters[
        'variables/getSupplyByID'
      ](instruction.supplyID);

      if (
        (limitTo === 'sampleBags' && supply?.type !== SupplyType.SAMPLE_BAG)
        || (limitTo === 'nonSampleBags' && supply?.type === SupplyType.SAMPLE_BAG)
      ) {
        return items;
      }

      const scannedSupplyItemIDs = supplyItemIDsBySupplyID[instruction.supplyID] ?? [];
      const scannedCount = scannedSupplyItemIDs.length;

      const itemsToAdd = Array.from(Array(instruction.quantity).keys())
        .map((index) => ({
          color: supply?.color,
          imageColor: supply?.imageColor,
          isScanned: index < scannedCount,
          key: generateID(),
          name: supply?.name ?? 'Unknown supply',
          supplyID: instruction.supplyID,
          supplyItemID: scannedSupplyItemIDs[index],
        }));
      return [...items, ...itemsToAdd];
    },
    [],
  );

  return scannableItems;
};

/**
 * Returns:
 * - Normal mode
 *   - all packable supplies for scanning
 * - Bag-only scanning mode
 *   - only sample bags (nurse specific, packers must scan all)
 */
export const initProcedureSuppliesToScan = (
  procedure: Procedure | undefined,
): ScannableItemWithKey[] => {
  const bagOnlyScanning = (
    getUserRole() === Role.NURSE
    && !!procedure?.disableSampleScanning
  );
  const limitTo = bagOnlyScanning ? 'sampleBags' : undefined;

  return initProcedureSuppliesScannableItems({ procedure, limitTo });
};

/**
 * Return non-sample bags for clicking in bag-only scanning mode (Used when
 * `Procedure.disabledSampleScanning: true`)
 */
export const initProcedureSuppliesToClick = (
  procedure: Procedure | undefined,
): ScannableItemWithKey[] =>
  initProcedureSuppliesScannableItems({
    procedure,
    limitTo: 'nonSampleBags',
  });

/**
 * Given a procedure, build a list of type `SupplyToCheck`. Processing options
 * may be passed to filter.
 *
 * Pass `from: 'samples'` if the list of supplies to start with should be based
 * on sorted samples (lab supplies)
 *
 * Pass `filterBehaviour: 'includeOnlyFiltered'` to flip the default filtering
 * behaviour and instead include only supplies that match the filter(s).
 */
export const initSuppliesToCheck = (
  procedure: Procedure | undefined,
  supplyInstructionOptionsToFilter: SupplyInstructionOptions[] = [],
  from: 'supplyInstructions' | 'samples' = 'supplyInstructions',
  filterBehaviour: 'excludeFiltered' | 'includeOnlyFiltered' = 'excludeFiltered',
): SupplyToCheck[] => {
  const store = getStore();

  if (!procedure) return [];

  let supplyInstructions: SupplyInstruction[];
  if (from === 'supplyInstructions') {
    supplyInstructions = Object.values(
      procedure?.supplyInstructionConfig?.supplyInstructions ?? {},
    );
  } else {
    const samples = store.getters[
      'itineraryFlow/getSortedExtendedSamples'
    ](procedure.id);
    supplyInstructions = samples.map((sample) => ({
      ...sample,
      instructions: [sample.processing],
      quantity: 1,
    }));
  }

  const suppliesToCheck = supplyInstructions.reduce<SupplyToCheck[]>(
    (currentSuppliesToCheck, item) => {
      const supply = store.getters['variables/getSupplyByID'](item.supplyID);

      const isSupplyFilteredOut = supplyInstructionOptionsToFilter.some((instructionFilter) => {
        const supplyHasInstruction = hasTypeOfSupplyInstructionSingleCheck(
          item,
          instructionFilter,
        );
        if (filterBehaviour === 'includeOnlyFiltered') return !supplyHasInstruction;
        return supplyHasInstruction;
      });

      if (!supply || isSupplyFilteredOut) return currentSuppliesToCheck;

      const { color, id, imageColor, name } = supply;

      const addedSuppliesToCheck = Array.from(Array(item.quantity).keys()).map(() => {
        const instructions = getSupplyInstructionsLabels(item.instructions);
        return {
          color,
          imageColor,
          instructions,
          isChecked: false,
          name,
          supplyID: id,
        };
      });

      return [...currentSuppliesToCheck, ...addedSuppliesToCheck];
    },
    [],
  );

  return suppliesToCheck;
};

// SHIFT

export const initShiftSupplyItemsToScan = (
  shiftID: string,
): ScannableItemWithKey[] => {
  const store = getStore();

  const shift = store.getters['shifts/getShiftByID'](shiftID);
  if (!shift) return [];
  const items = Object.keys(shift?.supplies ?? {})
    .map((id) => {
      const numberOfSupplies = shift.supplies?.[id];
      if (!numberOfSupplies) return [];
      const supplyItemsWithThisSupplyID = Object.values(
        shift.supplyItems ?? {},
      ).filter((item) => item.supplyID === id);
      const supply = store.getters['variables/getSupplyByID'](id);
      const itemsToScan = Array.from(
        { length: numberOfSupplies }, (_, index) => ({
          /**
           * `index` represents the iterator through the number of scanned
           * supply items that share this supply id
           *
           * If the scanned supply items with this supply id contains an item
           * at this index, the item has been scanned
          */
          isScanned: !!supplyItemsWithThisSupplyID[index],
          supplyID: id,
          key: generateID(),
          name: supply?.name ?? 'unknown supply',
          ...(!!supply?.imageColor && { imageColor: supply.imageColor }),
          ...(!!supply?.color && { color: supply.color }),
        }));
      return itemsToScan;
    }).flat();
  return items;
};

// USER

export const initDeliveredItemsToScan = (fulfillmentID: string): ScannableItemWithKey[] =>
  Object.values(getStore().state.auth.user?.supplies?.shopifyFulfillments?.[fulfillmentID]?.lineItems ?? [])
    .flat()
    .map((lineItem) =>
      Array.from(Array(lineItem?.quantity).keys()).map(() => ({
        skuID: lineItem?.sku ?? 'unknown sku',
        isScanned: false,
        key: generateID(),
        name: lineItem?.name ?? 'unknown item name',
      })),
    )
    .flat();
