
import { OfficeStatus } from '@caresend/types';
import { Loading, getStore } from '@caresend/ui-components';
import { hasStatus } from '@caresend/utils';
import { computed, defineComponent } from 'vue';

import ErrorNote from '@/components/layout/error/ErrorNote.vue';
import OfficeDashboard from '@/views/office/OfficeDashboard.vue';
import OfficeStatusPage from '@/views/office/OfficeStatusPage.vue';

export default defineComponent({
  name: 'OfficePage',
  components: {
    ErrorNote,
    Loading,
    OfficeDashboard,
    OfficeStatusPage,
  },
  setup() {
    const store = getStore();

    const currentOffice = computed(() => store.state.office.office);
    const isLoading = computed(() => store.state.office.officeLoading);
    const error = computed(() => store.state.office.officeLoadError);

    const currentOfficeIsApproved = computed(() =>
      hasStatus(currentOffice.value, OfficeStatus.APPROVED),
    );

    return {
      currentOffice,
      currentOfficeIsApproved,
      error,
      isLoading,
    };
  },
});
