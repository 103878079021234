import { EncryptedUser } from '@caresend/types';
import { OfficeState, initSentry, isProd } from '@caresend/ui-components';
import { formatDateOfBirth, formatPhoneNumber, isNullish } from '@caresend/utils';
import isEqual from 'lodash.isequal';
import Vue from 'vue';
import { Route } from 'vue-router';

import { SEGMENT_TOKEN, SENTRY_DSN } from '@/database/credentials';

export const initializeSentry = () => {
  initSentry(isProd, {
    Vue,
    dsn: SENTRY_DSN,
    release: `${process.env.VUE_APP_NAME}@${process.env.VUE_APP_VERSION}@${process.env.VUE_APP_CI_COMMIT_SHORT_SHA}`,
  });
};

/**
 * Segment
 */

export const initSegment = () => {
  window.analytics.load(SEGMENT_TOKEN);
};

export const trackPageViewSegment = (nextLocation: Route, prevLocation: Route) => {
  // Delay tracking page views for performance.
  setTimeout(() => {
    window.analytics.page('Navigate', {
      path: nextLocation.path,
      referrer: prevLocation.path,
    });
  }, 500);
};

export const identifyAnonymousUserSegment = () => {
  window.analytics.identify();
};

export const getSegmentIdentityFromState = (user: EncryptedUser, officeState: OfficeState) => {
  const { info, role, professionalInfo, certifications, status, interviewInfo } = user;
  const { firstName, lastName, email, phone, address, gender, dateOfBirth, languages, backgroundCheck } = info;
  const licences = professionalInfo?.licences;
  const npiNumber = professionalInfo?.npi;
  const malpracticeInsurance = professionalInfo?.malpracticeInsurance;
  const name = `${firstName} ${lastName}`;
  return {
    email,
    name,
    role,
    gender,
    dateOfBirth: formatDateOfBirth(dateOfBirth),
    officeName: officeState.office?.info.name,
    officeID: officeState.office?.id,
    phone: formatPhoneNumber(phone),
    address: address?.formattedAddress,
    medicalTitle: professionalInfo?.title,
    languagesName: Object.values(languages ?? {}).map((language) => language.language),
    languagesStatus: Object.values(languages ?? {}).map((language) => language.fluency),
    licensesNumber: Object.values(licences ?? {}).map((license) => license.number),
    licensesState: Object.values(licences ?? {}).map((license) => license.stateCode),
    licensesExpiry: Object.values(licences ?? {}).map((license) => license.expiryDate),
    licensesStatus: Object.values(licences ?? {}).map((license) => license.status),
    certificationsName: Object.values(certifications ?? {}).map((certification) => certification.name),
    certificationsState: Object.values(certifications ?? {}).map((certification) => certification.stateCode),
    certificationsExpiry: Object.values(certifications ?? {}).map((certification) => certification.expiryDate),
    npiNumber,
    status,
    videoInterview: interviewInfo !== undefined,
    backgroundCheck,
    malpracticeCarrier: Object.values(malpracticeInsurance ?? {}).map((insurance) => insurance.carrier),
    malpracticeExpiry: Object.values(malpracticeInsurance ?? {}).map((insurance) => insurance.expiryDate),
  };
};

export const identifyAuthenticatedUserSegment = (user: EncryptedUser, officeState: OfficeState) => {
  const segmentIdentity = getSegmentIdentityFromState(user, officeState);
  console.info('Identifying user', user.id);
  window.analytics.identify(user.id, segmentIdentity);
};

/**
 * We should only call segment `identify` for a user that has already been
 * identified during this session if the user details that we report in the
 * `identify` call have changed.
 */
export const maybeIdentifyUserAgainSegment = (
  user: EncryptedUser,
  prevUser: EncryptedUser,
  officeState: OfficeState,
) => {
  const previousSegmentIdentity = !isNullish(prevUser)
    ? getSegmentIdentityFromState(prevUser, officeState)
    : null;

  const newSegmentIdentity = getSegmentIdentityFromState(user, officeState);
  const hasSegmentIdentityUpdated = !isEqual(
    previousSegmentIdentity,
    newSegmentIdentity,
  );

  if (hasSegmentIdentityUpdated) {
    identifyAuthenticatedUserSegment(user, officeState);
  }
};
