
import { OfficeStatus } from '@caresend/types';
import { getStore } from '@caresend/ui-components';
import { hasStatus } from '@caresend/utils';
import { computed, defineComponent } from 'vue';

import InterviewScheduled from '@/components/offices/InterviewScheduled.vue';
import ScheduleInterview from '@/components/status/ScheduleInterview.vue';
import StatusPage from '@/components/status/StatusPage.vue';
import { officeStatusToInterviewScheduled } from '@/database/firebase/office';

export default defineComponent({
  name: 'OfficeStatusPage',
  components: {
    ScheduleInterview,
    InterviewScheduled,
    StatusPage,
  },
  setup() {
    const store = getStore();

    const office = computed(() => store.state.office.office);

    const statusIsInterviewScheduled = computed(() =>
      hasStatus(office.value, OfficeStatus.INTERVIEW_SCHEDULED),
    );

    const statusIsRequireInterview = computed(() =>
      hasStatus(office.value, OfficeStatus.REQUIRE_INTERVIEW),
    );

    const scheduleInterview = (interviewUri: string) => {
      if (office.value?.id) {
        officeStatusToInterviewScheduled(office.value?.id, interviewUri);
      }
    };

    return {
      scheduleInterview,
      statusIsInterviewScheduled,
      statusIsRequireInterview,
    };
  },
});
