import {
  ByID,
  ChartingItem,
  ChartingItemType,
  InstructionType,
  Procedure,
  ProcedureFlowType,
  ProcedureStatus,
  Sample,
  SampleStatus,
  SupplyInstructionOptions,
  TrackingEvent,
  WaypointAction,
  WaypointActionType,
  WaypointStatusName,
  YesNoOptionLabel,
} from '@caresend/types';
import {
  Getters,
  getStore,
  reportException,
  toastError,
  toastErrorAndReport,
} from '@caresend/ui-components';
import {
  generateID,
  getRawInstruction,
  hasStatus,
  hasTypeOfSupplyInstruction,
  hasTypeOfSupplyInstructionSingleCheck,
  isNullish,
  objToKeyValueArray,
  objectMap,
} from '@caresend/utils';
import update from 'immutability-helper';
import clonedeep from 'lodash.clonedeep';

import { LocationDeviationReason } from '@/components/shared/WaypointCompliance/model';
import { trackEvent } from '@/functions/tracking/tracking';
import type { RootState } from '@/store/model';
import type { ItineraryFlowGetters } from '@/store/modules/itineraryFlow';
import { ExtendedCollectedSample, ExtendedSample } from '@/store/modules/itineraryFlow/model';
import { trackDriveToWaypoint } from '@/views/nurse/helpers/tracking';

export const titleFastingInstruction = 'Is the patient fasting?';

// TODO: cleanup unused throughout

/**
 * Using an array of indices, recursively looks for nested group ChartingItem
 * children to find a single non-group ChartingItem.
 *
 * Example: [1, 2] - Root ChartingItem of index 1, nested non-group
 * ChartingItem of index 2.
 */
export const getChartingItemByIndexArray = (
  chartingItems: ChartingItem[] | undefined,
  indexArray: number[],
): ChartingItem | undefined => {
  if (!chartingItems) return;
  const deepItemArray = indexArray.reduce<ChartingItem[]>((prevItems, currentItemIndex, index) => {
    const currentItem = prevItems[currentItemIndex];
    if (!currentItem) throw new Error('Missing charting item');
    if (index === indexArray.length - 1) {
      return [currentItem];
    }
    if (currentItem.type === ChartingItemType.GROUP && currentItem.children) {
      return currentItem.children;
    }
    throw new Error('Expected group ChartingItem');
  }, chartingItems);
  const item = deepItemArray[0];
  return item;
};

/**
 * Sets charting items for nested items in group ChartingItems.
 */
export const updateNestedChartingItem = (
  chartingItems: ChartingItem[],
  indexArray: number[],
  newPartialChartingItem: ChartingItem,
) => {
  const chartingItem = getChartingItemByIndexArray(chartingItems, indexArray);

  if (indexArray.length < 2) throw new Error('Expected multiple items in indexArray');

  const indexArrayWithoutLastItem = indexArray.slice(0, indexArray.length - 1);
  const parentItem = getChartingItemByIndexArray(chartingItems, indexArrayWithoutLastItem);

  const items = parentItem?.children;
  if (!items) throw new Error('Missing charting item children');
  const lastIndex = indexArray[indexArray.length - 1];
  if (lastIndex === undefined) throw new Error('Missing index');
  items[lastIndex] = {
    ...chartingItem,
    ...newPartialChartingItem,
  };
};

/**
 * Returns a new list of charting items, with one item changed at the root
 * level of ChartingItems[]
 */
const getUpdatedChartingItemsForRootItem = (
  chartingItems: ChartingItem[],
  indexArray: number[],
  newPartialChartingItem: ChartingItem,
): ChartingItem[] => {
  const rootIndex = indexArray[0];
  if (isNullish(rootIndex)) throw new Error('First element missing in indexArray');
  if (indexArray.length > 1) throw new Error('Expected single item in indexArray');
  return update(chartingItems, {
    [rootIndex]: {
      $merge: newPartialChartingItem,
    },
  });
};

/**
 * Returns a new list of charting items, with one item changed at an item
 * nested in a group ChartingItem. Only supports 1 level of nesting.
 */
const getUpdatedChartingItemsForNestedItem = (
  chartingItems: ChartingItem[],
  indexArray: number[],
  newPartialChartingItem: ChartingItem,
) => {
  if (indexArray.length !== 2) throw new Error('Expected two items in indexArray');

  const newChartingItems = clonedeep(chartingItems);
  const firstIndex = indexArray[0];
  const secondIndex = indexArray[1];
  if (firstIndex === undefined || secondIndex === undefined) throw new Error('Missing index');
  const group = newChartingItems[firstIndex];
  if (!group) throw new Error('Missing group');
  const { children } = group;
  if (!children) throw new Error('Missing children');
  children[secondIndex] = {
    ...children[secondIndex],
    ...newPartialChartingItem,
  };

  return newChartingItems;
};

/**
 * Returns a new list of charting items, with one item changed.
 */
export const getUpdatedChartingItems = (
  chartingItems: ChartingItem[],
  indexArray: number[],
  newPartialChartingItem: ChartingItem,
): ChartingItem[] => {
  if (indexArray.length === 1) {
    return getUpdatedChartingItemsForRootItem(
      chartingItems,
      indexArray,
      newPartialChartingItem,
    );
  }
  return getUpdatedChartingItemsForNestedItem(
    chartingItems,
    indexArray,
    newPartialChartingItem,
  );
};

/**
 * Checks if the structure (types and titles) between two lists of charting
 * items are the same. Useful to skip overwriting a user’s in-progress charting
 * items saved in local storage when their device refetches charting items from
 * the db.
 */
export const hasChartingItemsStructureChanged = (
  oldItems: ChartingItem[],
  newItems: ChartingItem[],
): boolean => oldItems.length !== newItems.length || oldItems.some((oldItem, index) => {
  const newItem = newItems[index];
  if (
    oldItem.type !== newItem?.type
        || oldItem.title !== newItem?.title
  ) return true;
  if (oldItem.type === ChartingItemType.GROUP) {
    return hasChartingItemsStructureChanged(
      oldItem.children ?? [],
      newItem?.children ?? [],
    );
  }
  return false;
});

export const procedureFlowTypeIsLabDraw = (
  procedureID: string,
  rootState: RootState,
): boolean => {
  const taskID = rootState.procedures.procedures[procedureID]?.taskID;
  if (!taskID) return false;
  const task = rootState.variables.variables?.tasks[taskID];
  return task?.info.procedureFlowType === ProcedureFlowType.LAB_DRAW;
};

export const procedureFlowTypeIsUA = (
  procedureID: string,
  rootState: RootState,
): boolean => {
  const taskID = rootState.procedures.procedures[procedureID]?.taskID;
  if (!taskID) return false;
  const task = rootState.variables.variables?.tasks[taskID];
  return task?.info.procedureFlowType === ProcedureFlowType.LAB_DRAW;
};

export const procedureHasNurseInstruction = (
  procedureID: string,
  rootState: RootState,
): boolean => {
  const instructions = rootState.procedures.procedures[procedureID]?.instructions;
  const nurseInstruction = getRawInstruction(instructions, InstructionType.NURSE);
  return !!nurseInstruction?.instruction;
};

/**
 * The procedure must both have a required fasting instruction and a fasting
 * charting item to include the fasting charting step.
 */
export const procedureHasFastingRequired = (
  procedureID: string,
  rootState: RootState,
): boolean => {
  const procedure = rootState.procedures.procedures[procedureID];
  const instructions = procedure?.instructions;
  const nurseInstruction = getRawInstruction(instructions, InstructionType.FASTING);

  const indexFastingChartingItem
    = procedure?.charting?.items?.findIndex((item) => item.title === titleFastingInstruction) ?? 0;

  const items = rootState.itineraryFlow.proceduresInput[procedureID]?.chartingItems;
  let isChartingDefined = false;
  try {
    isChartingDefined = getChartingItemByIndexArray(
      items, [indexFastingChartingItem],
    ) !== undefined;
  } catch (error) {
    isChartingDefined = false;
  }

  return nurseInstruction?.instruction === YesNoOptionLabel.YES && isChartingDefined;
};

export const procedureHasFillByPatientInstruction = (
  procedureID: string,
  rootState: RootState,
): boolean => {
  const supplyInstructions = rootState.procedures.procedures[procedureID]?.supplyInstructionConfig?.supplyInstructions;
  return hasTypeOfSupplyInstruction(
    supplyInstructions,
    SupplyInstructionOptions.FILL_BY_PATIENT,
  );
};

export const procedureHasTransferFromContainerInstruction = (
  procedureID: string,
  rootState: RootState,
): boolean => {
  const supplyInstructions = rootState.procedures.procedures[procedureID]?.supplyInstructionConfig?.supplyInstructions;
  return hasTypeOfSupplyInstruction(
    supplyInstructions,
    SupplyInstructionOptions.TRANSFER_FROM_CONTAINER,
  );
};

export const getSampleToDiscard = (
  procedureID: string,
  rootGetters: Getters<ItineraryFlowGetters>,
): ExtendedSample | undefined => {
  const samples = rootGetters['itineraryFlow/getSortedExtendedSamples'](procedureID);

  const firstDiscardSample = samples.find((sample) =>
    hasTypeOfSupplyInstructionSingleCheck(
      sample,
      SupplyInstructionOptions.DISCARD_AFTER_TRANSFER,
    ),
  );

  return firstDiscardSample;
};

const hiddenSampleProcessing = [
  SupplyInstructionOptions.CENTRIFUGE,
  SupplyInstructionOptions.DISCARD_AFTER_TRANSFER,
  SupplyInstructionOptions.FILL_BY_PATIENT,
  SupplyInstructionOptions.REFRIGERATE_AFTER_PROCESSING,
  SupplyInstructionOptions.TRANSFER_FROM_CONTAINER,
] as const;

type HiddenSampleProcessing = (typeof hiddenSampleProcessing)[number];

export type NonHiddenSampleProcessing = Exclude<SupplyInstructionOptions, HiddenSampleProcessing>

const isNonHiddenSampleProcessing = (
  option: SupplyInstructionOptions,
): option is NonHiddenSampleProcessing =>
  !hiddenSampleProcessing.includes(option as any);

/**
 * Get an array of SupplyInstructionOptions that have been marked as required,
 * excluding hidden options not displayed to nurses.
 */
export const getRequiredSampleProcessing = (
  processing: Sample['processing'],
): NonHiddenSampleProcessing[] => {
  const instructionsKeyValObj = objToKeyValueArray(processing);
  // Do not include centrifuge, transfer, discard, and fill instruction at all on nurse side at this time
  // per Product Team.
  const requiredProcessing = instructionsKeyValObj
    .filter(({ value: isRequired }) => isRequired)
    .map(({ key: instruction }) => instruction)
    .filter(isNonHiddenSampleProcessing);

  return requiredProcessing;
};

/**
 * Given a sample, return a computed `ExtendedSample` object for use in
 * the frontend.
 */
export const getExtendedSampleForSample = (
  sample: Sample,
  rootState: RootState,
): ExtendedSample => {
  const supply = rootState.variables.variables?.supplies[sample.supplyID];

  if (!supply) throw toastError(`Missing supply ${sample.supplyID}`);

  return {
    ...sample,
    color: supply.color,
    imageColor: supply.imageColor,
    name: supply.name,
    noScanAfterCollection: !!supply.noScanAfterCollection,
    orderWeight: supply.orderWeight ?? 0,
    // questions: getQuestionsForSample(sample),
  };
};

/**
 * Returns all sample check questions by sample ID.
 */
export const getExtendedSamples = (
  samples: ByID<Sample>,
  rootState: RootState,
): ByID<ExtendedSample> => {
  const extendedSample = objectMap(samples, (sample) =>
    getExtendedSampleForSample(sample, rootState),
  );

  return extendedSample;
};

export const getSortedExtendedSamples = (
  procedureID: string,
  rootState: RootState,
): ExtendedSample[] => {
  const samples = rootState.procedures.procedures[procedureID]?.samples ?? {};
  const extendedSamplesByID = getExtendedSamples(samples, rootState);
  const extendedSamples = Object.values(extendedSamplesByID);
  return extendedSamples.sort((a, b) => (a.orderWeight < b.orderWeight) ? 1 : -1);
};

export const getExtendedCollectedSamples = (
  procedureID: string,
  rootState: RootState,
): ExtendedCollectedSample[] => {
  const samples = getSortedExtendedSamples(procedureID, rootState);
  const scanningDisabled = !!rootState.variables.featureFlags
    ?.disablePatientVisitSupplyScanning;

  // Get the serial number from the supply item ID
  const samplesWithSerials: (ExtendedSample & { serial?: string })[]
    = samples.map((sample) => {
      const { supplyItemID } = sample;
      const serial = supplyItemID?.split('-')[1];
      // TODO: May be able to remove special logic below for click mode if we
      // make mock supplyItemIDs match our format.
      if (scanningDisabled) {
        return { ...sample, serial: `scanDisabled-${generateID()}` };
      }
      return { ...sample, serial };
    });

  const collectedSamples = samplesWithSerials
    .filter((sample): sample is ExtendedCollectedSample => {
      const hasSupplyItemID = !!sample.supplyItemID;
      const hasSerial = !!sample.serial;
      const isCollected = hasStatus(sample, SampleStatus.COLLECTED);
      if (!isCollected) return false;
      if (!hasSupplyItemID || !hasSerial) {
        toastErrorAndReport(
          `Missing supply item ID or serial in collected sample ${sample.id}`,
        );
        return false;
      }

      return true;
    });

  return collectedSamples;
};

// const isNonProcessingQuestion = (
//   question: SampleCheckQuestion,
// ): question is SampleCheckQuestion & { key: NonProcessingSampleCheckItem } =>
//   !!Object.values<string>(NonProcessingSampleCheckItem).includes(question.key);

// export const shouldUseCachedQuestion = (
//   cachedQuestion?: SampleCheckQuestion,
// ): cachedQuestion is SampleCheckQuestion => {
//   if (!cachedQuestion) return false;

//   // Clear out invalid answers caused by refreshing the page without selecting
//   // a reason on the modal.
//   if (isNonProcessingQuestion(cachedQuestion)) {
//     switch (cachedQuestion.key) {
//       case NonProcessingSampleCheckItem.COLLECTED:
//         if (
//           cachedQuestion.selected === YesNoOptionID.NO
//           && !cachedQuestion.collectionFailure
//         ) return false;
//         break;
//       case NonProcessingSampleCheckItem.DROPPED_OFF:
//         if (
//           cachedQuestion.selected === YesNoOptionID.NO
//           && !cachedQuestion.dropOffFailure
//         ) return false;
//         break;
//     }
//   }

//   // Processing questions cached as disabled should stay that way, but only
//   // the saved db state should be used to determine if drop-off questions
//   // are disabled, otherwise drop-off questions could get stuck in an
//   // incorrect disabled state.
//   const isDisabledProcessing = !!cachedQuestion?.disabled
//     && cachedQuestion.key !== NonProcessingSampleCheckItem.DROPPED_OFF;

//   // Use the cached question data if it was answered or a disabled
//   // processing question
//   const useCached = (
//     !isNullish(cachedQuestion?.selected)
//     || isDisabledProcessing
//   );

//   return useCached;
// };

/**
 * Attempt to gracefully merge cached question data with question data
 * initialized based on the samples in the db.
 */
// export const mergeSampleQuestions = (
//   oldQuestions: SampleCheckQuestion[] | undefined,
//   newQuestions: SampleCheckQuestion[],
// ): SampleCheckQuestion[] => {
//   if (oldQuestions) {
//     const mergedQuestions = newQuestions.map((question) => {
//       // Look for a cached question with the same key.
//       const matchingQuestion = oldQuestions.find((oldQuestion) =>
//         question.key === oldQuestion.key,
//       );

//       return shouldUseCachedQuestion(matchingQuestion)
//         ? matchingQuestion
//         : question;
//     });

//     return mergedQuestions;
//   }

//   return newQuestions;
// };

/**
 * Merge to prevent overwriting the user's cached sample checks.
 */
// export const mergeSamples = (
//   oldExtendedSample: ByID<ExtendedSample>,
//   newExtendedSample: ByID<ExtendedSample>,
// ): ByID<ExtendedSample> => {
//   const mergedSubSamples = objectMap(
//     newExtendedSample,
//     (extendedSample, sampleID) => {
//       const newQuestions = extendedSample.questions;
//       const oldQuestions = oldExtendedSample[sampleID]?.questions;
//       const mergedQuestions = mergeSampleQuestions(oldQuestions, newQuestions);

//       return {
//         ...extendedSample,
//         questions: mergedQuestions,
//       };
//     },
//   );

//   return mergedSubSamples;
// };

/**
 * Pick-up waypoint actions will always show a checkbox.
 *
 * Drop-off waypoint actions that contain at least one procedure with
 * samples and that have not been given custom instructions for the
 * checkbox will not display a checkbox, and will only display the
 * samples entry. If a custom instruction was answered, both will show.
 */
export const waypointActionRequiresCheckbox = (
  waypointAction: WaypointAction,
  procedures: Procedure[],
): boolean => {
  // If canceled procedures were filtered out prior to calling this function,
  // and no procedures remain, no checkbox is required.
  if (!procedures.length) return false;

  if (
    waypointAction?.type === WaypointActionType.PICKUP
    || waypointAction?.instructions
  ) return true;

  const someProcedureHasSamples = procedures?.some((procedure) =>
    Object.keys(procedure.samples ?? {}).length,
  ) ?? false;

  const someProcedureIsNotCanceled = procedures?.some((procedure) =>
    !hasStatus(procedure, ProcedureStatus.CANCELED),
  );

  return someProcedureIsNotCanceled && !someProcedureHasSamples;
};

/**
 * Get the sample checks that are asked during the charting step.
 * (Did you fill the tubes + processing instructions)
 */
// export const getProcessingQuestions = (
//   extendedSample: ExtendedSample,
// ): SampleCheckQuestion[] => extendedSample.questions.filter((question) =>
//   question.key === NonProcessingSampleCheckItem.COLLECTED
//   || Object.values<string>(SupplyInstructionOptions).includes(question.key),
// );

/**
 * Get the sample checks that are asked during the drop-off step.
 * (Dropped off/not dropped off)
 */
// export const getDropOffQuestions = (
//   extendedSample: ExtendedSample,
// ): SampleCheckQuestion[] => extendedSample.questions.filter((question) =>
//   question.key === NonProcessingSampleCheckItem.DROPPED_OFF,
// );

// export const someProcessingQuestionUnanswered = (
//   extendedSample: ExtendedSample[],
// ) => extendedSample.some((data) => {
//   const processingQuestions = getProcessingQuestions(data);
//   return processingQuestions.some((question) =>
//     !question.disabled && isNullish(question.selected),
//   );
// });

// export const someDropOffQuestionUnanswered = (
//   extendedSample: ExtendedSample[],
// ) => extendedSample.some((data) => {
//   const dropOffQuestions = getDropOffQuestions(data);
//   return dropOffQuestions.some((question) =>
//     !question.disabled && isNullish(question.selected),
//   );
// });

export const setWaypointStatusToTransitWaypoint = async (
  waypointID: string,
) => {
  const store = getStore();

  try {
    await store.dispatch('waypoint/updateWaypointAndActionStatus', {
      waypointID,
      waypointStatus: WaypointStatusName.TRANSIT_WAYPOINT,
    });
  } catch (error) {
    console.error('Error updating waypoint and/or waypoint action status');
    reportException(error);
  }

  trackDriveToWaypoint(waypointID);
};

export const setWaypointStatusToAtWaypoint = async (
  itineraryID: string,
  waypointID: string,
  reason?: LocationDeviationReason,
) => {
  const store = getStore();

  try {
    await store.dispatch('waypoint/updateWaypointAndActionStatus', {
      waypointID: waypointID,
      waypointStatus: WaypointStatusName.AT_WAYPOINT,
    });
  } catch (error) {
    console.error('Error updating waypoint and/or waypoint action status');
    reportException(error);
  }

  trackEvent(TrackingEvent.ARRIVED_AT_WAYPOINT, {
    nurseID: store.state.auth.user?.id,
    itineraryID: itineraryID,
    waypointID: waypointID,
    tooFarReason: reason,
  }, true);
};
