import { WaypointStatusName } from '@caresend/types';
import {
  buildMaybePartnerRoute as buildMaybePartnerLocation,
  getStore,
  initFlowHelpers,
} from '@caresend/ui-components';
import { hasStatus } from '@caresend/utils';
import { CurrentLocation, Location, Route } from 'vue-router';

import {
  getProceduresKitCompletion,
  getWaypointBagCompletion,
  getWaypointProcedureAndWaypointActionIDs,
} from '@/functions/supplies/get';
import { trackDebugEvent } from '@/functions/tracking/tracking';
import { actionBlockedRoute, placeDetailsRoute, samplesDropOffRoute } from '@/router/locations';
import { RouteName, routeNames } from '@/router/model';
import {
  flowConfig,
  getFlattenedItinerarySteps,
  getItineraryFlowHelpers,
  getItineraryStepMap,
} from '@/store/modules/itineraryFlow/steps/root';

// ROUTER HELPERS

/**
 * If the nurse re-enters the patient visit waypoint from the itinerary screen,
 * skip past certain steps if allowable based on waypoint status.
 */
const getPatientWaypointEntryRouteName = (
  itineraryID: string,
  waypointID: string,
): RouteName | null => {
  const waypoint = getStore().state.waypoint.waypoints[waypointID];
  const flatSteps = getFlattenedItinerarySteps(itineraryID);

  const status = waypoint?.status.status;

  switch (status) {
    // If the nurse is in transit, skip to the "Go to Patient" step.
    case WaypointStatusName.TRANSIT_WAYPOINT: {
      const goToPatientStep = flatSteps.find((step) =>
        step.routeName === routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_GO_TO_PATIENT,
      );
      if (goToPatientStep) return goToPatientStep.routeName;
      break;
    }

    // If the nurse is at the patient’s location,
    // - skip to the action blocked step if there are pending tasks (later
    //   steps not in stepmap yet)
    // - skip to the patient list if procedure bag packing is complete
    // - otherwise, skip to procedure bag packing
    case WaypointStatusName.AT_WAYPOINT: {
      const actionBlockedStep = flatSteps.find((step) =>
        step.routeName === routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_ACTION_BLOCKED,
      );

      if (actionBlockedStep) {
        return actionBlockedStep.routeName;
      }

      const proceduresSupplyStep = flatSteps.find((step) =>
        step.routeName === routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURES_SUPPLY,
      );
      const patientListStep = flatSteps.find((step) =>
        step.routeName === routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_PATIENT_LIST,
      );

      const ids = getWaypointProcedureAndWaypointActionIDs(waypoint);
      const proceduresKitsComplete = getProceduresKitCompletion(ids);
      const waypointBagComplete = getWaypointBagCompletion(waypoint);
      const procedurePackingComplete = proceduresKitsComplete && waypointBagComplete;
      trackDebugEvent('gettingWaypointEntryForAtWaypointNurse', {
        proceduresKitsComplete,
        waypointBagComplete,
        procedurePackingComplete,
        proceduresSupplyStep: !!proceduresSupplyStep,
      });

      if (!procedurePackingComplete && proceduresSupplyStep) {
        return proceduresSupplyStep.routeName;
      }

      if (patientListStep) {
        return patientListStep.routeName;
      }
    }
  }

  // Otherwise go to first step (currently always "Patient waypoint details")
  return flatSteps[0]?.routeName ?? null;
};

/**
 * If the nurse re-enters the patient visit waypoint from the itinerary screen,
 * skip past certain steps if allowable based on waypoint status.
 */
export const getPatientWaypointEntryLocation = (
  itineraryID: string,
  waypointID: string,
): CurrentLocation | null => {
  const routeName = getPatientWaypointEntryRouteName(itineraryID, waypointID);
  if (!routeName) return null;
  const location = buildMaybePartnerLocation({
    name: routeName,
    params: {
      itineraryID,
      waypointID,
    },
  });
  return location;
};

export const getMailInEntryLocation = (
  itineraryID: string,
  waypointID: string,
): CurrentLocation | null => {
  const stepMap = getItineraryStepMap(itineraryID);
  const { getStepByRouteNameAndAssociatedID } = initFlowHelpers(flowConfig, stepMap);
  const itineraryStep = getStepByRouteNameAndAssociatedID(
    routeNames.ITINERARY_FLOW_ITINERARY,
    itineraryID,
  );

  const subStepRouteNames = [...itineraryStep?.subSteps?.[waypointID]?.keys() ?? []];

  // Get data to skip past driving steps if user has previously completed them.
  const drivingStepIndex = subStepRouteNames.findIndex((routeName) =>
    routeName === routeNames.ITINERARY_FLOW_PACKED_MAIL_IN_WAYPOINT_DRIVE_TO_LOCATION,
  );
  const firstRouteNameAfterDriving = subStepRouteNames[drivingStepIndex + 1];

  const [firstRouteName] = subStepRouteNames ?? [];

  const waypoint = getStore().state.waypoint.waypoints[waypointID];

  const alreadyArrived = hasStatus(waypoint, WaypointStatusName.AT_WAYPOINT);
  const entryRouteName = alreadyArrived
    ? firstRouteNameAfterDriving
    : firstRouteName;

  // Prevent returning non-mail-in steps
  if (!entryRouteName?.includes('PackedMailInWaypoint')) return null;
  return buildMaybePartnerLocation({
    name: entryRouteName,
    params: {
      itineraryID,
      waypointID,
    },
  });
};

/**
 * Drop-off currently exists outside of the itinerary flow architecture.
 *
 * TODO: Re-implement via stepmap when drop-off refactored to use flow logic.
 */
export const getDropOffEntryLocation = (
  itineraryID: string,
  waypointID: string,
) => {
  const store = getStore();

  const blocked = !!store.getters[
    'waypoint/getRouteToReadyActionInItinerary'
  ](itineraryID);

  const waypoint = store.state.waypoint.waypoints[waypointID];
  if (!waypoint) throw Error(`Missing waypoint ${waypointID}`);

  const params = {
    itineraryID,
    waypointID,
  };

  // Skip past driving steps if user has previously completed them.
  if (hasStatus(waypoint, WaypointStatusName.AT_WAYPOINT)) {
    if (!blocked) return samplesDropOffRoute({ params });
    return actionBlockedRoute({ params });
  }

  return placeDetailsRoute({ params });
};

export const getPickDropWaypointEntryLocation = (
  itineraryID: string,
  waypointID: string,
): CurrentLocation | null => {
  const waypointHasMailInActions = getStore().getters[
    'waypoint/waypointHasMailInWaypointActions'
  ](waypointID);

  if (waypointHasMailInActions) {
    return getMailInEntryLocation(itineraryID, waypointID);
  }

  return getDropOffEntryLocation(itineraryID, waypointID);
};

export const routeIsLastInCurrentItinerarySubSteps = (route: Route): boolean => {
  const currentRouteName = route.name;
  const currentSubSteps = getItineraryFlowHelpers(route).getCurrentSubSteps(route);
  const currentSubStepsRouteNameArray = [...currentSubSteps?.keys() ?? []];
  if (!currentRouteName || !currentSubStepsRouteNameArray.length) return false;

  const index = currentSubStepsRouteNameArray.indexOf(currentRouteName);
  return index === currentSubStepsRouteNameArray.length - 1;
};

export const getPatientLocation = (
  itineraryID: string,
  waypointID: string,
  waypointActionID: string,
): Location | null => {
  const stepMap = getItineraryStepMap(itineraryID);
  const { getStepByRouteNameAndAssociatedID } = initFlowHelpers(flowConfig, stepMap);
  const patientListStep = getStepByRouteNameAndAssociatedID(
    routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_PATIENT_LIST,
    waypointID,
  );
  const subStepRouteNames = patientListStep?.subSteps?.[waypointActionID]?.keys();
  const [firstRouteName] = subStepRouteNames ?? [];
  if (!firstRouteName) return null;
  return buildMaybePartnerLocation({
    name: firstRouteName,
    params: {
      waypointActionID,
    },
  });
};

export const getProcedureRoute = (
  itineraryID: string,
  waypointID: string,
  waypointActionID: string,
  procedureID: string,
): Location | null => {
  const stepMap = getItineraryStepMap(itineraryID);
  const { getStepByRouteNameAndAssociatedID } = initFlowHelpers(flowConfig, stepMap);
  const procedureListStep = getStepByRouteNameAndAssociatedID(
    routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_PATIENT_LIST,
    waypointID,
  );
  const subStepRouteNames = procedureListStep?.subSteps?.[procedureID]?.keys();
  const [firstRouteName] = subStepRouteNames ?? [];
  if (!firstRouteName) return null;
  return buildMaybePartnerLocation({
    name: firstRouteName,
    params: {
      procedureID,
      waypointActionID,
      waypointID,
    },
  });
};

export const getMailInShipmentLocation = (
  itineraryID: string,
  waypointID: string,
  shipmentID: string,
): Location | null => {
  const stepMap = getItineraryStepMap(itineraryID);
  const { getStepByRouteNameAndAssociatedID } = initFlowHelpers(flowConfig, stepMap);
  const shipmentListStep = getStepByRouteNameAndAssociatedID(
    routeNames.ITINERARY_FLOW_PACKED_MAIL_IN_WAYPOINT_SHIPMENTS,
    waypointID,
  );
  const subStepRouteNames = shipmentListStep?.subSteps?.[shipmentID]?.keys();
  const [firstRouteName] = subStepRouteNames ?? [];
  if (!firstRouteName) return null;
  return buildMaybePartnerLocation({
    name: firstRouteName,
    params: {
      itineraryID,
      waypointID,
      shipmentID,
    },
  });
};

export const getCentrifugationProcedureLocation = (
  itineraryID: string,
  processingActionID: string,
  procedureID: string,
): Location | null => {
  const stepMap = getItineraryStepMap(itineraryID);
  const { getStepByRouteNameAndAssociatedID } = initFlowHelpers(flowConfig, stepMap);
  const proceduresListStep = getStepByRouteNameAndAssociatedID(
    routeNames.ITINERARY_FLOW_CENTRIFUGATION_PACK_SPECIMEN_BAGS,
    processingActionID,
  );
  const subStepRouteNames = proceduresListStep?.subSteps?.[procedureID]?.keys();
  const [firstRouteName] = subStepRouteNames ?? [];
  if (!firstRouteName) return null;

  return buildMaybePartnerLocation({
    name: firstRouteName,
    params: {
      itineraryID,
      processingActionID,
      procedureID,
    },
  });
};

const getFirstSampleSubStepRouteName = (
  currentRoute: Route,
  sampleID: string,
): RouteName | null => {
  const currentStep = getStore().getters[
    'itineraryFlow/getCurrentStep'
  ](currentRoute);
  const { subSteps } = currentStep ?? {};
  const sampleSubStep = subSteps?.[sampleID];
  if (!sampleSubStep) return null;
  const [nextSampleSubStep] = sampleSubStep.values();
  if (!nextSampleSubStep) return null;
  return nextSampleSubStep.routeName;
};

export const getFirstSampleSubStepLocation = (
  currentRoute: Route,
  procedureID: string,
  sampleID: string,
): CurrentLocation | null => {
  const routeName = getFirstSampleSubStepRouteName(currentRoute, sampleID);
  if (!routeName) return null;
  return buildMaybePartnerLocation({
    name: routeName,
    params: {
      sampleID,
      procedureID,
    },
  });
};
