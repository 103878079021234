
import { ButtonComponent, Loading } from '@caresend/ui-components';
import { defineComponent, onMounted, onUnmounted } from 'vue';

export default defineComponent({
  name: 'LazyLoading',
  components: { ButtonComponent, Loading },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    endOfList: {
      type: Boolean,
      default: false,
    },
    /**
     * If set to true, a button will be displayed at the bottom of the list.
     * The auto load will be disabled and the button will trigger the load event.
     */
    manualFetch: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const manageBottomScroll = () => {
      const totalPageHeight = document.body.scrollHeight;
      const scrollPoint = window.scrollY + window.innerHeight;

      // check if we hit the bottom of the page
      if (scrollPoint >= totalPageHeight && !props.isLoading && !props.manualFetch) {
        emit('load');
      }
    };
    onMounted(() => {
      window.addEventListener('scroll', manageBottomScroll, false);
    });
    onUnmounted(() => window.removeEventListener('scroll', manageBottomScroll, false));
    return {};
  },
});
