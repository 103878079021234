import '@/utils/initDevtools';
import '@/registerServiceWorker';
import 'buefy/dist/buefy.css';
import 'focus-visible';
import '@stripe/stripe-js';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

import '@/style/index.sass';
import { FormHeader, getRouter, getStore, initFirebase } from '@caresend/ui-components';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faAngleLeft,
  faAngleRight,
  faArrowAltCircleDown,
  faArrowCircleRight,
  faArrowLeft,
  faArrowRight,
  faBarcode,
  faBars,
  faBirthdayCake,
  faBox,
  faBriefcaseMedical,
  faCalendarDay,
  faCarSide,
  faChartArea,
  faCheck,
  faCheckCircle,
  faChevronCircleRight,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faClinicMedical,
  faClock,
  faCloud,
  faCloudUploadAlt,
  faCog,
  faCommentAlt,
  faCopy,
  faDesktop,
  faDollarSign,
  faDoorOpen,
  faDraftingCompass,
  faEllipsisV,
  faEnvelope,
  faExclamationCircle,
  faExpandAlt,
  faExpandArrowsAlt,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faFile,
  faFilePdf,
  faFilter,
  faFlag,
  faFlask,
  faGlobeAmericas,
  faGripHorizontal,
  faHome,
  faHouseUser,
  faIdBadge,
  faInfoCircle,
  faLemon,
  faLock,
  faLongArrowAltLeft,
  faMap,
  faMapMarkedAlt,
  faMapMarker,
  faMapMarkerAlt,
  faMinus,
  faMobile,
  faNotEqual,
  faPaperPlane,
  faPaperclip,
  faPaw,
  faPen,
  faPhone,
  faPhoneAlt,
  faPlay,
  faPlus,
  faPlusCircle,
  faQuestionCircle,
  faRoute,
  faSearch,
  faShoppingBasket,
  faSignInAlt,
  faSignOutAlt,
  faSortDown,
  faSortUp,
  faStore,
  faStream,
  faSync,
  faSyncAlt,
  faSyringe,
  faThumbtack,
  faTimes,
  faTimesCircle,
  faTrash,
  faUndo,
  faUnlock,
  faUser,
  faUserAlt,
  faUserClock,
  faUserMd,
  faUserPlus,
  faUsers,
  faVial,
  faVideo,
  faWalking,
  faWallet,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Buefy from 'buefy';
import * as dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import calendar from 'dayjs/plugin/calendar';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isToday from 'dayjs/plugin/isToday';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { PiniaVuePlugin, createPinia } from 'pinia';
import Vue from 'vue';
import VueCalendly from 'vue-calendly';
import VueMobileDetection from 'vue-mobile-detection';

import App from '@/App.vue';
import DateTimeView from '@/components/layout/date/DateTimeView.vue';
import ImagePane from '@/components/layout/image/ImagePane.vue';
import Notes from '@/components/layout/rows/Notes.vue';
import PageHeader from '@/components/layout/text/PageHeader.vue';
import { initSegment, initializeSentry } from '@/functions/tracking';
import { initRouter } from '@/router';
import { initStore } from '@/store';

dayjs.extend(advancedFormat);
dayjs.extend(calendar);
dayjs.extend(customParseFormat);
dayjs.extend(isToday);
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);

initFirebase();
initSegment();
initializeSentry();
initStore();

/* eslint-disable vue/component-definition-name-casing */
/**
 * TODO: Remove all global components.
 */
Vue.component('image-pane', ImagePane);
Vue.component('date-view', DateTimeView);
Vue.component('page-header', PageHeader);
Vue.component('form-header', FormHeader);
Vue.component('notes', Notes);

Vue.use(VueMobileDetection);

library.add(
  faAngleLeft,
  faAngleRight,
  faArrowAltCircleDown,
  faArrowCircleRight,
  faArrowLeft,
  faArrowRight,
  faBarcode,
  faBars,
  faBirthdayCake,
  faBox,
  faBriefcaseMedical,
  faCalendarDay,
  faCarSide,
  faChartArea,
  faCheck,
  faCheckCircle,
  faChevronCircleRight,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faClinicMedical,
  faClock,
  faCloud,
  faCloudUploadAlt,
  faCog,
  faCommentAlt,
  faCopy,
  faDesktop,
  faDollarSign,
  faDoorOpen,
  faDraftingCompass,
  faEllipsisV,
  faEnvelope,
  faExclamationCircle,
  faExpandAlt,
  faExpandArrowsAlt,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faFile,
  faFilePdf,
  faFilter,
  faFlag,
  faFlask,
  faGlobeAmericas,
  faGripHorizontal,
  faHome,
  faHouseUser,
  faIdBadge,
  faInfoCircle,
  faLemon,
  faLock,
  faLongArrowAltLeft,
  faMap,
  faMapMarkedAlt,
  faMapMarker,
  faMapMarkerAlt,
  faMinus,
  faMobile,
  faNotEqual,
  faPaperPlane,
  faPaperclip,
  faPaw,
  faPen,
  faPen,
  faPhone,
  faPhoneAlt,
  faPlay,
  faPlus,
  faPlusCircle,
  faQuestionCircle,
  faRoute,
  faSearch,
  faShoppingBasket,
  faSignInAlt,
  faSignOutAlt,
  faSortDown,
  faSortUp,
  faStore,
  faStream,
  faSync,
  faSyncAlt,
  faSyringe,
  faThumbtack,
  faTimes,
  faTimesCircle,
  faTrash,
  faUndo,
  faUnlock,
  faUser,
  faUserAlt,
  faUserClock,
  faUserMd,
  faUserPlus,
  faUsers,
  faVial,
  faVideo,
  faWalking,
  faWallet,
);
Vue.component('vue-fontawesome', FontAwesomeIcon);
Vue.use(Buefy, {
  defaultIconComponent: 'vue-fontawesome',
  defaultIconPack: 'fas',
});
Vue.use(VueCalendly);

Vue.config.productionTip = false;

Vue.use(PiniaVuePlugin);

const initApp = async () => {
  await getStore().dispatch('app/initApp');
  const pinia = createPinia();

  initRouter();

  new Vue({
    name: 'Pro',
    router: getRouter(),
    store: getStore(),
    pinia,
    render: (h) => h(App),
  }).$mount('#app');
};

initApp();
