
import { Shift } from '@caresend/types';
import { BasicCard } from '@caresend/ui-components';
import { PropType, defineComponent } from 'vue';

import ShiftCard from '@/components/shifts/ShiftCard/ShiftCard.vue';

export default defineComponent({
  name: 'ShiftsByDate',
  components: {
    BasicCard,
    ShiftCard,
  },
  props: {
    formattedDate: {
      type: String,
      required: true,
    },
    shifts: {
      type: Array as PropType<Shift[]>,
      required: true,
    },
  },
});
