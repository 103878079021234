import {
  BarcodeType,
  BatchItem,
  ByID,
  DbRef,
  Procedure,
  ProcedureStatus,
  Sample,
  SupplyItem,
  SupplyItemWithSupplyID,
  SupplyType,
  Waypoint,
} from '@caresend/types';
import {
  getRoute,
  getStore,
  getValueOnce,
  toastError,
  toastErrorAndReport,
} from '@caresend/ui-components';
import {
  arrayToObj,
  deduplicateArrayOfObjectsByID,
  hasStatus,
  isNullish,
  nullishFilter,
} from '@caresend/utils';

import { initProcedureSuppliesScannableItems } from '@/functions/supplies/init';
import {
  ItemWithBarcodeType,
  ScannableItem,
  ScannableItemWithKey,
  hasBarcodeType,
  supplyNameAmberBag,
  supplyNameInsulatedBag,
} from '@/functions/supplies/scanning';
import { routeNames } from '@/router/model';
import { GetPreviousSupplyItem, SupplyItemType } from '@/store/modules/procedures/model';
import { ProcedureAndWaypointActionID } from '@/store/modules/supplies/model';
import { SupplyItemForMySupplies } from '@/views/nurse/supplies/MySupplies/model';

// PROCEDURE

export const getProceduresKitCompletion = (
  ids: ProcedureAndWaypointActionID[],
): boolean => ids.every((idSet) => {
  const procedure = getStore().state.procedures.procedures[idSet.procedureID];
  return !!procedure?.supplyItems && !!procedure?.kitSupplyItem?.id;
});

export const getCurrentSampleAmberBag = (
  procedure: Procedure | undefined,
  supplyItemID: string | undefined,
): SupplyItemWithSupplyID | undefined =>
  Object.values(procedure?.amberBags ?? {})
    .find((amberBag) => amberBag.relatedSupplyItems?.[supplyItemID ?? '']);

export const getPreviousItemFromBag = (
  procedureID: string,
  sampleID: string,
  lookTwoStepsBack = false,
): GetPreviousSupplyItem | undefined => {
  const route = getRoute();
  const store = getStore();
  const oneStepBackLocation = store.getters[
    'itineraryFlow/getBackStepLocationInItineraryFlow'
  ](route.value);
  let previousItemRouteName = oneStepBackLocation?.name;
  if (lookTwoStepsBack && oneStepBackLocation) {
    const twoStepsBackLocation = store.getters[
      'itineraryFlow/getBackStepLocationInItineraryFlow'
    ](oneStepBackLocation);
    previousItemRouteName = twoStepsBackLocation?.name;
  }

  const procedure: Procedure | undefined = store.getters['procedures/getProcedureByID'](procedureID);
  const sample: Sample | undefined = procedure?.samples?.[sampleID];

  let previousSupplyItem: GetPreviousSupplyItem | undefined;
  if (
    previousItemRouteName
      === routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURE_SAMPLE_CHARTING_SCAN_AMBER_BAG
  ) {
    const amberBag = getCurrentSampleAmberBag(procedure, sample?.supplyItemID);
    if (!amberBag) return;
    previousSupplyItem = {
      supplyItem: amberBag,
      type: SupplyItemType.AMBER_BAG,
    };
  } else if (
    previousItemRouteName
      === routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURE_SAMPLE_COMPLETE_CHARTING
  ) {
    if (!sample?.supplyItemID) return;
    previousSupplyItem = {
      supplyItem: {
        id: sample.supplyItemID,
        supplyID: sample.supplyID,
      },
      type: SupplyItemType.CONTAINER,
    };
  } else if (
    previousItemRouteName
      === routeNames.ITINERARY_FLOW_PATIENT_WAYPOINT_PROCEDURE_SAMPLE_CHARTING_SCAN_INSULATED_BAG
  ) {
    if (!procedure?.insulatedBag) return;
    previousSupplyItem = {
      supplyItem: procedure?.insulatedBag,
      type: SupplyItemType.INSULATED_BAG,
    };
  }

  return previousSupplyItem;
};

export const getProcedureBagScanningCompletion = (
  procedure: Procedure | undefined,
  scanningDisabled?: boolean,
): boolean => {
  if (!procedure) return false;

  if (scanningDisabled) {
    return !!procedure.kitSupplyItem?.id && !!procedure.supplyItems;
  }

  const procedureSupplyIDsToScan = initProcedureSuppliesScannableItems({ procedure })
    .map((item) => item.supplyID)
    .sort();
  const procedureSupplyItems = Object.values(procedure?.supplyItems ?? {})
    .map((item) => item.supplyID)
    .sort();

  const isBagScanningCompleted = procedureSupplyItems.length !== 0
    && JSON.stringify(procedureSupplyIDsToScan) === JSON.stringify(procedureSupplyItems)
    && !!procedure.kitSupplyItem?.id;
  return isBagScanningCompleted;
};

const getQuantitiesBySupplyID = (items: { supplyID?: string }[]): ByID<number> =>
  items.reduce<ByID<number>>((quantities, item) => {
    const { supplyID } = item;
    if (!supplyID) throw Error('Missing supply ID');
    return {
      ...quantities,
      [supplyID]: (quantities[supplyID] || 0) + 1,
    };
  }, {});

/**
 * Given a list of scannable items, check whether at least the appropriate
 * quantity of matching supplies have been stored already at
 * `Procedure.supplyItems`
 */
export const getProcedureContainsAllItems = (
  procedure: Procedure | undefined,
  items: ScannableItemWithKey[],
): boolean => {
  const expectedQuantityBySupplyID = getQuantitiesBySupplyID(items);
  const storedQuantityBySupplyID = getQuantitiesBySupplyID(
    Object.values(procedure?.supplyItems ?? {}),
  );

  const someQuantityNotMet = Object.entries(expectedQuantityBySupplyID).some(
    ([supplyID, expectedQuantity]) =>
      (storedQuantityBySupplyID[supplyID] || 0) < expectedQuantity,
  );

  return !someQuantityNotMet;
};

export const getSampleContainerName = (sample: Sample | undefined): string =>
  sample?.type !== SupplyType.TUBE ? SupplyItemType.CONTAINER : SupplyItemType.TUBE;

export const getPreviousItemLabel = (
  procedureID: string,
  sampleID: string,
): string => {
  const previousSupplyItem = getPreviousItemFromBag(
    procedureID,
    sampleID,
  );
  const sample: Sample | undefined = getStore().getters[
    'procedures/getProcedureByID'
  ](procedureID)?.samples?.[sampleID];
  switch (previousSupplyItem?.type) {
    case SupplyItemType.AMBER_BAG:
      return supplyNameAmberBag;
    case SupplyItemType.INSULATED_BAG:
      return supplyNameInsulatedBag;
    default:
      return getSampleContainerName(sample);
  }
};

export const areProcedureBagSupplyItemsAlreadyScanned = (
  procedure: Procedure | undefined,
): boolean => !!procedure?.supplyItems;

// SHIFT

export const areShiftSuppliesScanned = (
  shiftID: string,
): boolean => {
  const shift = getStore().getters['shifts/getShiftByID'](shiftID);
  if (!shift) return false;
  const itemsToScan = Object.keys(shift?.supplies ?? {})
    .map((id) => {
      const numberOfSupplies = shift.supplies?.[id];
      if (!numberOfSupplies) return [];
      return Array.from({ length: numberOfSupplies }, (i: number) => i);
    }).flat();
  return itemsToScan.length === Object.values(shift.supplyItems ?? {}).length;
};

/** Get the ID of the earliest itinerary on a shift */
export const getFirstItineraryIDOnShift = (
  shiftID: string | undefined,
): string | undefined => {
  const store = getStore();

  const shift = store.getters['shifts/getShiftByID'](shiftID);
  if (!shift) return;

  let firstItineraryStart: number | undefined;
  let firstItineraryID: string | undefined;
  Object.keys(shift.itineraries ?? {}).forEach((itineraryID) => {
    const itineraryStartTime = store.getters['waypoint/getItineraryByID'](itineraryID)?.startTime;
    if (!itineraryStartTime?.timestamp) return;
    if (!firstItineraryStart || itineraryStartTime?.timestamp < firstItineraryStart) {
      firstItineraryStart = itineraryStartTime?.timestamp;
      firstItineraryID = itineraryID;
    }
  });
  return firstItineraryID;
};

// SUPPLY

export const getSupplyNameByID = (supplyID?: string): string | undefined => {
  if (isNullish(supplyID)) return;

  const supply = getStore().getters['variables/getSupplyByID'](supplyID);
  if (!supply) return;

  const { name } = supply;
  return name.replace('CareSend ', '');
};

export const getProcedureKitName = (
  procedureID: string | undefined,
): string => {
  const store = getStore();

  const supplyID = store.getters['variables/getTaskByID'](
    store.getters['procedures/getProcedureByID'](procedureID)?.taskID,
  )?.kitSupplyID;
  const supplyKitName = store.getters['variables/getSupplyByID'](supplyID ?? '')?.name ?? 'kit';
  return supplyKitName;
};

// SUPPLY ITEM

export const getSupplyItemFromSupplyItemBarcode = async (
  item: ItemWithBarcodeType<BarcodeType.SUPPLY_ITEM>,
): Promise<SupplyItemWithSupplyID | null> => {
  const barcodeID = item.barcode.id;
  const { supplyID } = item;
  // Confirm the supply item exists in the db.
  const supplyItem = await getValueOnce<SupplyItem>(
    `${DbRef.SUPPLY_ITEMS}/${barcodeID}`,
  );
  if (!supplyItem) {
    toastErrorAndReport(`Supply item ${barcodeID} not found`);
    return null;
  }

  const supplyItemWithSupplyID: SupplyItemWithSupplyID = {
    id: supplyItem.id,
    // Missing supplyID is normal in some cases where we do not pass it in
    // with itemsToScan.
    supplyID: supplyID ?? 'undefined supplyID',
  };

  return supplyItemWithSupplyID;
};

export const getSupplyItemsFromBatchItem = async (
  itemToScan: ItemWithBarcodeType<BarcodeType.BATCH_ITEM>,
): Promise<SupplyItemWithSupplyID[]> => {
  const barcodeID = itemToScan.barcode.id;
  const { skuID, supplyID } = itemToScan;
  const batchItem = await getValueOnce<BatchItem>(
    `${DbRef.BATCH_ITEMS}/${barcodeID}`,
  );
  const individualSkuID = await getValueOnce<string>(
    `${DbRef.BATCH_SKUS}/${skuID}/individualSkuID`,
  );
  if (!batchItem) {
    toastErrorAndReport(`Batch item ${barcodeID} not found`);
    return [];
  }

  const supplyItemPromises = batchItem.serials.map(async (serial) => {
    const supplyItemID = `${individualSkuID}-${serial}`;
    // Confirm the supply item exists in the db.
    const supplyItem = await getValueOnce<SupplyItem>(
      `${DbRef.SUPPLY_ITEMS}/${supplyItemID}`,
    );
    if (!supplyItem) {
      toastErrorAndReport(`Supply item ${supplyItemID} not found`);
      return null;
    }

    const supplyItemWithSupplyID: SupplyItemWithSupplyID = {
      id: supplyItem.id,
      supplyID: supplyID ?? 'undefined supplyID',
    };

    return supplyItemWithSupplyID;
  });

  const supplyItems = await Promise.all(supplyItemPromises);

  return supplyItems.filter(nullishFilter);
};

export const getSupplyItemsFromItemsToScan = async (
  itemsToScan: ScannableItem[],
): Promise<ByID<SupplyItemWithSupplyID>> => {
  const supplyItemPromises: Promise<SupplyItemWithSupplyID[]>[] = itemsToScan.map(async (item) => {
    if (hasBarcodeType<BarcodeType.BATCH_ITEM>(item, BarcodeType.BATCH_ITEM)) {
      return getSupplyItemsFromBatchItem(item);
    }

    if (hasBarcodeType<BarcodeType.SUPPLY_ITEM>(item, BarcodeType.SUPPLY_ITEM)) {
      const supplyItem = await getSupplyItemFromSupplyItemBarcode(item);
      return supplyItem ? [supplyItem] : [];
    }

    // Fall back to using supplyID and supplyItemID on scannable item if
    // barcode is not available.
    if (item.supplyID && item.supplyItemID) {
      return [{
        id: item.supplyItemID,
        supplyID: item.supplyID,
      }];
    }

    toastErrorAndReport('Missing barcode.');
    return [];
  });

  const supplyItemsArray = (await Promise.all(supplyItemPromises)).flat();

  return arrayToObj(supplyItemsArray, 'id');
};

// SUPPLY TRANSFER

/**
 * Returns the non-canceled procedures associated with the draft waypoint on
 * the supply transfer that have kit supplies. Displays a toast error for
 * each procedure that does not have a kit supply.
 */
export const getSupplyTransferProcedures = (
  supplyTransferID: string,
): Procedure[] => {
  const store = getStore();

  const supplyTransfer = store.state.supplies.supplyTransfers[supplyTransferID];
  const { draftWaypointID } = supplyTransfer ?? {};
  if (!draftWaypointID) {
    throw toastErrorAndReport(
      `Unable to find draft waypoint on supply transfer ${supplyTransferID}`,
    );
  }
  const draftWaypoint = store.state.waypoint.draftWaypoints[draftWaypointID];
  const procedureIDs = Object.keys(draftWaypoint?.procedures ?? {});
  const procedures = procedureIDs.map((procedureID) => {
    const procedure = store.state.procedures.procedures[procedureID];
    if (!procedure) {
      toastErrorAndReport(`Missing procedure ${procedureID}. It will be ignored.`);
    }
    return procedure;
  }).filter(nullishFilter);

  const filteredProcedures = procedures.filter((procedure) => {
    if (hasStatus(procedure, ProcedureStatus.CANCELED)) return false;

    const task = store.state.variables.variables?.tasks[procedure.taskID];
    const hasKitSupply = !!task?.kitSupplyID;

    if (!hasKitSupply) {
      toastError(`Procedure ${procedure.id} missing kit supply. It will be ignored.`);
      return false;
    }
    return true;
  });

  return filteredProcedures;
};

/**
 * Returns the non-canceled procedure IDs associated with the draft waypoint on
 * the supply transfer that have kit supplies. Displays a toast error for
 * each procedure that does not have a kit supply.
 */
export const getSupplyTransferProcedureIDs = (
  supplyTransferID: string,
): string[] => {
  const procedures = getSupplyTransferProcedures(supplyTransferID);
  return procedures.map((procedure) => procedure.id);
};

// USER

export const getNurseSupplyItems = (): SupplyItemForMySupplies[] => {
  const store = getStore();

  const user = store.getters['auth/getUser'];
  if (!user) return [];
  const supplyItems: SupplyItem[] = store.getters['supplies/getNurseSupplyItems'](user);
  const supplyItemsForMySupplies: SupplyItemForMySupplies[] = deduplicateArrayOfObjectsByID(
    supplyItems.map((supplyItem) => {
      const quantity = supplyItems.filter(
        (supplyItem2) => supplyItem.skuID === supplyItem2.skuID,
      ).length;
      const sku = store.getters['supplies/getSkuByID'](supplyItem.skuID);
      const supply = store.getters['variables/getSupplyByID'](
        sku?.supplyID ?? '',
      );
      return {
        id: supplyItem.skuID,
        name: supply?.name ?? '',
        quantity: quantity,
      };
    }),
  );
  return supplyItemsForMySupplies;
};

// WAYPOINT

export const getWaypointBagCompletion = (
  waypoint: Waypoint | undefined,
): boolean => waypoint?.supplyItem !== undefined;

/**
 * Builds procedure bag objects for all non-canceled procedures on a waypoint.
 */
export const getWaypointProcedureAndWaypointActionIDs = (
  waypoint: Waypoint | undefined,
): ProcedureAndWaypointActionID[] => {
  const store = getStore();

  const waypointActions = waypoint?.waypointActions;

  const idSetByProcedureID = waypointActions
    ?.reduce<ByID<ProcedureAndWaypointActionID>>((ids, { id }) => {
      const waypointAction = store.state.waypoint.waypointActions[id];
      const idSetArray: ProcedureAndWaypointActionID[]
        = Object.keys(waypointAction?.procedures ?? {})
          .map((procedureID) => {
            const procedure = store.state.procedures.procedures[procedureID];
            if (procedure && !hasStatus(procedure, ProcedureStatus.CANCELED)) {
              return {
                procedureID,
                waypointActionID: id,
              };
            }
            return null;
          }).filter(nullishFilter);

      return {
        ...ids,
        ...arrayToObj(idSetArray, 'procedureID'),
      };
    }, {}) ?? {};

  return Object.values(idSetByProcedureID);
};

/**
 * Checks if the supply item is in a procedure bag on any of the procedures
 * on a given waypoint.
 */
export const supplyItemAlreadyInProcedureBagAtThisWaypoint = (
  waypointID: string,
  supplyItemID: string,
): boolean => {
  const store = getStore();

  const waypoint = store.getters['waypoint/getWaypointByID'](waypointID);
  const procedures = getWaypointProcedureAndWaypointActionIDs(waypoint);

  return Object.values(procedures)
    .some(({ procedureID }) => {
      const procedure = store.getters['procedures/getProcedureByID'](procedureID);
      return procedure?.kitSupplyItem?.id === supplyItemID
        || procedure?.supplyItems?.[supplyItemID] !== undefined;
    });
};
