import { HeadingValues, portalJobsRoute } from '@caresend/ui-components';
import { computed } from 'vue';
import { RouteConfig } from 'vue-router';

import { routeNames } from '@/router/model';

const routesProfile: RouteConfig[] = [
  {
    path: '/:partnerName?/profile',
    name: routeNames.PROFILE,
    component: () => import(
      /* webpackChunkName: 'chunk-profile' */
      '@/views/profile/Profile.vue'
    ),
    meta: {
      authRequired: true,
      profileCompletedRequired: true,
      heading: () => computed<HeadingValues | undefined>(() => ({
        title: 'Profile',
        backLocation: portalJobsRoute(),
      })),
    },
  },
  {
    path: '/:partnerName?/orderKit',
    name: routeNames.ORDER_KIT,
    component: () => import(
      /* webpackChunkName: 'chunk-order-kit' */
      '@/views/profile/OrderKit.vue'
    ),
    meta: {
      authRequired: true,
    },
  },
];

export { routesProfile };
